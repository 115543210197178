/* eslint-disable no-debugger */
// Split into basket area (redeem, product, order)
import { toast } from 'react-toastify';
import {
  CREATE_BASKET_START,
  CREATE_BASKET_ERROR,
  CREATE_BASKET_SUCCESS,
  GET_BASKET_START,
  GET_BASKET_ERROR,
  GET_BASKET_SUCCESS,
  UPDATE_BASKET_START,
  UPDATE_BASKET_ERROR,
  BASKET_ERROR_TOASTIFY,
  UPDATE_BASKET_SUCCESS,
  GET_SHIPPINGQUOTE_START,
  GET_SHIPPINGQUOTE_SUCCESS,
  GET_SHIPPINGQUOTE_ERROR,
  SET_SHIPPINGQUOTE_START,
  SET_SHIPPINGQUOTE_SUCCESS,
  SET_SHIPPINGQUOTE_ERROR,
  FINALIZE_BASKET_START,
  FINALIZE_BASKET_ERROR,
  FINALIZE_BASKET_SUCCESS,
  ADD_REDEEM_START,
  ADD_REDEEM_ERROR,
  ADD_REDEEM_SUCCESS,
  REMOVE_REDEEM_START,
  REMOVE_REDEEM_ERROR,
  REMOVE_REDEEM_SUCCESS,
  ADD_PRODUCT_START,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_SUCCESS,
  GET_ORDER_START,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  ACCOUNT_TYPE,
  ORDER_REFERENCE,
  PRODUCT_TYPE,
  DELIVERY_METHODS,
  PAYMENT_METHODS,
  valitorString,
} from '../constants';
import {
  DEFAULT_COUNTRY_NAMES,
  DEFAULT_COUNTRY,
  SHIPPING_METHODS,
  postCodeErrorComparisonString,
  postCodeErrorMessage,
  SALES_CHANNELS,
  SALES_CHANNELS_GROUP,
  REDEEM_PATH,
} from '../constants/config';
import { setSalesChannel, redirectTo } from './config';
import { getProducts } from './shop';
import mutationCreateBasket from './mutations/createBasket.graphql';
import mutationUpdateBasket from './mutations/updateBasket.graphql';
import mutationCreateShippingQuote from './mutations/createShippingQuote.graphql';
import mutationFinalizeBasket from './mutations/finalizeBasket.graphql';
import getBasketQuery from './queries/getBasket.graphql';
import getProductsQuery from './queries/getProducts.graphql';
import productStockInfoQuery from './queries/getProductStockInfo.graphql';
import getOrderQuery from './queries/getOrder.graphql';
import getOrderPaymentInfoQuery from './queries/getOrderPaymentInfo.graphql';
import {
  getFilteredQuote,
  calculateQuoteId,
} from '../helpers/sortShippingQuote';
import { getFromState, urlPrefix, sortBy, merge } from '../helpers';
import getWindow from '../helpers/windowProvider';
import {
  setDepartmentBasketId,
  setSalesChannelBasketId,
  removeBasketId,
  clearUserStorage,
} from '../helpers/userStorage';

import { triggerGenericGAEvent } from '../helpers/googleTagManager';

import {
  addProductSuccess,
  addProductStart,
  postcodeError,
  showError,
} from '../components/Notifications/notificationTypes';

const ORDER_CREATION_TIMEOUT = 3000;
const ORDER_CREATION_POLL_INTERVAL = 1000;

export function createBasket(salesChannelId) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { countryCode, currency },
      user: {
        account: {
          departmentId,
          emailOverride,
          nameOverride,
          phoneNumberOverride,
          deliveryAddressOverride,
        } = {},
      } = {},
    } = getState();
    dispatch({
      type: CREATE_BASKET_START,
      payload: {
        loading: true,
        basketLoading: true,
      },
    });
    // Why not return the full basket here to avoid createBasket/ getBasket?
    // Because getBasket will include product presentation keys ...
    try {
      const {
        data: {
          baskets: {
            createBasket: { id },
          },
        },
      } = await client.mutate({
        mutation: mutationCreateBasket,
        variables: {
          input: {
            salesChannelId,
          },
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      // createBasket only accepts salesChannel so need to do a second request using update
      let buyer;
      if (departmentId) {
        buyer = {
          accountId: departmentId,
          accountType: ACCOUNT_TYPE.B2B,
          name: nameOverride,
          email: emailOverride,
          phone: phoneNumberOverride,
          address: {
            ...deliveryAddressOverride,
          },
        };
      }
      try {
        await client.mutate({
          mutation: mutationUpdateBasket,
          variables: {
            input: {
              id,
              buyer,
              paymentCurrency: currency,
            },
          },
        });
      } catch (err) {
        dispatch({
          type: CREATE_BASKET_ERROR,
          error: err,
          payload: {
            loading: false,
            basketLoading: false,
          },
        });
      }

      dispatch({
        type: CREATE_BASKET_SUCCESS,
        payload: {
          id,
          salesChannelId,
          formData: {
            countryCode,
          },
          loading: false,
          basketLoading: false,
          initialBasket: true,
        },
      });
      if (departmentId) {
        setDepartmentBasketId({ departmentId, basketId: id });
      } else {
        setSalesChannelBasketId({ salesChannelId, basketId: id, countryCode });
      }
    } catch (error) {
      dispatch({
        type: CREATE_BASKET_ERROR,
        error,
        payload: {
          loading: false,
          basketLoading: false,
        },
      });
    }
    return getFromState(getState(), 'basket');
  };
}

export function getBasket(id) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, countryCode },
    } = getState();
    dispatch({
      type: GET_BASKET_START,
      payload: {
        loading: true,
        basketLoading: true,
      },
      meta: {
        id,
      },
    });

    if (!id) {
      dispatch({
        type: GET_BASKET_ERROR,
        error: 'No basket id provided',
        payload: {
          loading: false,
          basketLoading: false,
        },
      });
      return getFromState(getState(), 'basket');
    }

    try {
      const {
        data: {
          baskets: { basketById },
        },
      } = await client.query({
        query: getBasketQuery,
        variables: {
          id,
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      // Persisted baskets are periodically (every day) purged from the backend.
      // Null is returned for non-existent baskets.
      if (!basketById) {
        removeBasketId({ basketId: id });
        throw new Error(`There is no basket with id '${id}'`);
      }

      const { deliveries, salesChannelId } = basketById;
      const productIds = [];

      // Move to reducer
      const { issues } = basketById;
      // Add issue on each productLine by matching relatedTo === lineId
      deliveries.forEach(delivery => {
        delivery.productLines.forEach(productLine => {
          const issue = issues.find(i => i.relatedTo === productLine.lineId);

          if (issue) {
            // eslint-disable-next-line no-param-reassign
            productLine.issue = issue;
          }
        });
      });

      deliveries.forEach(({ productLines }) => {
        productLines.forEach(({ productId }) => {
          if (!productIds.includes(productId)) productIds.push(productId);
        });
      });
      let basketPresentations = [];
      if (productIds.length) {
        const {
          data: { productsBySalesChannel },
        } = await client.query({
          query: getProductsQuery,
          variables: {
            input: {
              productIds,
              salesChannelId,
              expectedLanguage: locale,
              includePresentationKeys: ['productTitle', 'productLogo'],
            },
          },
          context: {
            service: 'cms',
            fetchPolicy: 'cache-first',
          },
        });
        basketPresentations = productsBySalesChannel;
      }

      const stockQueries = [];

      for (let i = 0; i < productIds.length; i += 1) {
        const productId = productIds[i];
        stockQueries.push(
          client.query({
            query: productStockInfoQuery,
            variables: {
              id: productId,
              context: {
                service: 'graphqlHost',
                fetchPolicy: 'network-only',
              },
            },
          }),
        );
      }

      const sortedStockInfos = (await Promise.all(stockQueries)).reduce(
        (
          obj,
          {
            data: {
              products: { stockInfo },
            },
          },
          index,
        ) => {
          const tempObj = { ...obj };
          tempObj[productIds[index]] = stockInfo;
          return tempObj;
        },
        {},
      );

      dispatch({
        type: GET_BASKET_SUCCESS,
        payload: {
          ...basketById,
          defaultCountryCode: countryCode,
          basketPresentations,
          loading: false,
          basketLoading: false,
          initialBasket: true,
          stockInfos: sortedStockInfos,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_BASKET_ERROR,
        error,
        payload: {
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'basket');
  };
}

export function updateBasketItem({
  addProducts,
  doClearProducts = true,
  paymentCurrency,
}) {
  return async (dispatch, getState, { client }) => {
    const {
      basket: { id },
      intl: { countryCode },
    } = getState();

    dispatch({
      type: UPDATE_BASKET_START,
    });

    if (!id) {
      dispatch({
        type: UPDATE_BASKET_ERROR,
        error: 'No basket id provided',
        payload: {
          loading: false,
          basketLoading: false,
        },
      });
      return getFromState(getState(), 'basket');
    }

    let updateBasket = {};
    try {
      const {
        data: { baskets },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id,
            doClearProducts,
            addProducts,
            paymentCurrency,
          },
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      updateBasket = baskets.updateBasket || {};
      dispatch({
        type: UPDATE_BASKET_SUCCESS,
        payload: {
          ...updateBasket,
          quotes: [],
          defaultCountryCode: countryCode,
          shippingQuoteError: false,
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BASKET_ERROR,
        error,
        payload: {
          ...updateBasket,
        },
      });
      dispatch({
        type: BASKET_ERROR_TOASTIFY,
        payload: {
          errorToastify: true,
          errorToastifyFull: getFromState(getState(), 'error'),
        },
      });
    }
    return getFromState(getState(), 'basket');
  };
}

export function resetErrorState() {
  return {
    type: BASKET_ERROR_TOASTIFY,
    payload: {
      errorToastify: undefined,
      errorToastifyFull: undefined,
    },
  };
}

export function addRedeem({ voucher, salesChannelGroup }) {
  return async (dispatch, getState, { client, history }) => {
    const {
      intl: { locale, countryCode, currency },
      basket: { id },
    } = getState();

    dispatch({
      type: ADD_REDEEM_START,
      payload: {
        loading: true,
      },
    });

    try {
      const {
        data: {
          baskets: { updateBasket },
        },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id,
            paymentCurrency: currency,
            addRedeems: [
              {
                ...voucher,
              },
            ],
          },
        },
      });

      triggerGenericGAEvent({
        eventData: {
          eventCategory: 'Giftcard',
          eventAction: 'Redeemed',
          eventLabel: '',
        },
      });

      dispatch({
        type: ADD_REDEEM_SUCCESS,
        payload: {
          ...updateBasket,
          loading: false,
        },
      });
    } catch (error) {
      // Add sales channels groups as enum
      if (
        error.graphQLErrors &&
        salesChannelGroup === SALES_CHANNELS_GROUP.WEBSITE
      ) {
        const wrongSalesChannelError = error.graphQLErrors.find(
          ({ extensions: { code } = {} }) =>
            code === 'AttemptToRedeemInWrongSalesChannel',
        );

        const portalId =
          wrongSalesChannelError?.extensions?.data?.salesChannelId;

        if (portalId) {
          return portalId;
        }
      }
      dispatch({
        type: ADD_REDEEM_ERROR,
        error,
        payload: {
          loading: false,
        },
      });

      const voucherAlreadyAdded = error?.graphQLErrors?.find(
        ({ extensions: { code } = {} }) => code === 'VoucherAdded',
      );

      if (voucherAlreadyAdded) {
        history.push(urlPrefix('/shop', { locale, countryCode, currency }));
      } else {
        showError(getFromState(getState(), 'error'));
      }
      return null;
    }
    return getFromState(getState(), 'basket');
  };
}

export function removeRedeem({ id: voucherId, boughtByB2bDepartment }) {
  return async (dispatch, getState, { client }) => {
    const {
      basket: { id },
      intl: { countryCode },
      config: { countries },
    } = getState();

    dispatch({
      type: REMOVE_REDEEM_START,
      payload: {
        loading: true,
      },
      meta: {
        id: voucherId,
      },
    });

    try {
      const {
        data: {
          baskets: { updateBasket },
        },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id,
            removeRedeemIds: [voucherId],
          },
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      dispatch({
        type: REMOVE_REDEEM_SUCCESS,
        payload: {
          ...updateBasket,
          loading: false,
        },
      });
      if (updateBasket.vouchers && updateBasket.vouchers.length === 0) {
        clearUserStorage();
        await dispatch(setSalesChannel(SALES_CHANNELS.B2C));
        if (countries.includes(countryCode)) {
          dispatch(redirectTo({ pathname: '/' }));
        } else {
          // Non-B2C country redirect to welcome page
          dispatch(redirectTo({ pathname: REDEEM_PATH }));
        }
      } else if (boughtByB2bDepartment) {
        // This is only set in Redeem Overview popup - as that is present in shop page and products needs to be updated
        await dispatch(getProducts());
      }
    } catch (error) {
      dispatch({
        type: REMOVE_REDEEM_ERROR,
        error,
        payload: {
          loading: false,
        },
      });
    }
    return getFromState(getState(), 'basket');
  };
}

export function addProduct(product) {
  return async (dispatch, getState, { client }) => {
    const {
      basket: { id },
    } = getState();

    dispatch({
      type: ADD_PRODUCT_START,
      payload: {
        loading: true,
      },
    });

    toast.success(addProductStart());

    try {
      const {
        data: {
          baskets: { updateBasket },
        },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id,
            addProducts: [
              {
                ...product,
              },
            ],
          },
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      // Need this to get product presentation keys of newly added products
      // During rewrite of flattenBasketData product presentation keys should be moved to a separate store segment
      await dispatch(getBasket(id));

      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: {
          ...updateBasket,
          loading: false,
        },
      });

      toast.success(addProductSuccess());
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_ERROR,
        error,
        payload: {
          loading: false,
        },
      });
      showError(getFromState(getState(), 'error'));

      error.graphQLErrors?.forEach(gqlError => {
        if (gqlError?.message?.includes(postCodeErrorComparisonString)) {
          toast.error(postcodeError(postCodeErrorMessage));
        }
      });
    }
    return getFromState(getState(), 'basket');
  };
}

export function setBuyer(name, value) {
  return {
    type: 'SET_BUYER',
    payload: {
      name,
      value,
    },
  };
}

export function editDelivery(orderLine, name, value) {
  return {
    type: 'EDIT_DELIVERY',
    payload: {
      orderLine,
      name,
      value,
    },
  };
}

export function setAcceptTerms(value) {
  return {
    type: 'ACCEPT_TERMS',
    payload: {
      value,
    },
  };
}

export function selectPaymentMethod(value) {
  return {
    type: 'PAYMENT_METHOD',
    payload: {
      value,
    },
  };
}

export function setOrderReference(value) {
  return {
    type: ORDER_REFERENCE,
    payload: {
      value,
    },
  };
}

const delay = t =>
  new Promise(resolve => {
    setTimeout(resolve, t);
  });

const pollUntilDone = (fn, interval, timeout) => {
  const start = Date.now();
  const run = () =>
    fn().then(dataResult => {
      const {
        data: {
          orders: { orderByPublicId },
        },
      } = dataResult;
      if (orderByPublicId) {
        return dataResult;
      }
      if (timeout !== 0 && Date.now() - start > timeout) {
        const throwError = message => ({ message });
        throw throwError('Timeout');
      } else {
        // run again with a short delay
        return delay(interval).then(run);
      }
    });
  return run();
};

export function getShippingQuote({ basketId, deliveryId }) {
  return async (dispatch, getState, { client }) => {
    const existingQuotes = getState().basket.quotes;
    dispatch({
      type: GET_SHIPPINGQUOTE_START,
      payload: {
        shippingQuoteLoading: true,
      },
    });

    try {
      const {
        data: {
          baskets: {
            createShippingQuote: { quotes },
          },
        },
      } = await client.mutate({
        mutation: mutationCreateShippingQuote,
        variables: {
          input: {
            basketId,
            deliveryId,
          },
        },
      });

      dispatch({
        type: GET_SHIPPINGQUOTE_SUCCESS,
        payload: {
          shippingQuoteLoading: false,
          quotes: {
            ...existingQuotes,
            [deliveryId]: quotes,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SHIPPINGQUOTE_ERROR,
        error,
        payload: {
          shippingQuoteLoading: false,
          shippingQuoteError: error,
          quotes: {
            [deliveryId]: [],
          },
        },
      });
      showError(getFromState(getState(), 'error'));
    }
    const { quotes } = getFromState(getState(), 'basket');
    return quotes;
  };
}

export function getAndSetShippingQuotes(deliveryIds, basketId) {
  const { Pacsoft, MyPackCollect } = SHIPPING_METHODS;
  return async (dispatch, getState, { client }) => {
    if (!deliveryIds || !deliveryIds.length) {
      return getFromState(getState(), 'basket');
    }

    const shippingQuotePromises = deliveryIds.map(deliveryId => {
      return dispatch(getShippingQuote({ basketId, deliveryId }));
    });

    const shippingQuoteResults = await Promise.all(shippingQuotePromises);
    const mergedQuotes = merge(shippingQuoteResults);

    const setShippingQuotes = Object.keys(mergedQuotes)
      .map(deliveryId => {
        const sortedQuotes = mergedQuotes[deliveryId].sort(
          sortBy('priceNoTax'),
        );

        if (!sortedQuotes || !sortedQuotes[0]) {
          return undefined;
        }

        const currentDelivery = getState()?.basket?.data?.reduce(
          (returnValue, currentValue) => {
            if (currentValue.id === deliveryId) {
              return currentValue;
            }
            return returnValue;
          },
          {},
        );
        const {
          recipientAddress,
          valueCurrency,
          giftcardValue,
          quantity,
        } = currentDelivery;

        const recipientCountryCode = recipientAddress?.countryCode;
        const recipientCurrency = valueCurrency;
        const giftcardValueTotal = giftcardValue * quantity;

        let shippingQuoteId;

        if (
          sortedQuotes?.length &&
          Object.keys(DEFAULT_COUNTRY_NAMES).some(
            country => recipientCountryCode === country,
          )
        ) {
          if (
            recipientCountryCode === DEFAULT_COUNTRY_NAMES.NO ||
            recipientCountryCode === DEFAULT_COUNTRY_NAMES.FI
          ) {
            shippingQuoteId = sortedQuotes[0].id;
          } else {
            shippingQuoteId = calculateQuoteId(
              recipientCountryCode,
              recipientCurrency,
              giftcardValueTotal,
              sortedQuotes,
            );
            if (!shippingQuoteId) {
              if (recipientCountryCode === DEFAULT_COUNTRY) {
                shippingQuoteId = getFilteredQuote(sortedQuotes, Pacsoft);
              } else {
                shippingQuoteId = getFilteredQuote(sortedQuotes, MyPackCollect);
              }
            }
          }
        }

        return {
          deliveryId,
          shippingQuoteId,
        };
      })
      .filter(item => item !== undefined);

    try {
      dispatch({
        type: SET_SHIPPINGQUOTE_START,
        payload: {
          shippingQuoteLoading: true,
        },
      });
      const {
        data: {
          baskets: { updateBasket },
        },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id: basketId,
            setShippingQuotes,
          },
        },
        context: {
          service: 'graphqlHost',
          fetchPolicy: 'network-only',
        },
      });

      dispatch({
        type: SET_SHIPPINGQUOTE_SUCCESS,
        payload: {
          shippingQuoteLoading: false,
          ...updateBasket,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_SHIPPINGQUOTE_ERROR,
        error,
        payload: {
          shippingQuoteLoading: false,
        },
      });
      dispatch(getBasket(basketId));
      showError(getFromState(getState(), 'error'));
    }
    return getFromState(getState(), 'basket');
  };
}

export function checkoutBasket(
  buyer,
  paymentMethod = PAYMENT_METHODS.PSP,
  reference,
) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale, countryCode, currency },
      basket: { id, data, presentationData },
    } = getState();

    const deliveriesWithoutQuote = [
      ...new Set(
        data
          .filter(
            item =>
              item.deliveryMethod === DELIVERY_METHODS.PHYSICAL &&
              presentationData[item.productId].productType ===
                PRODUCT_TYPE.giftCard &&
              !item.shippingQuote,
          )
          .map(item => item.id),
      ),
    ];

    await dispatch(getAndSetShippingQuotes(deliveriesWithoutQuote, id));

    dispatch({
      type: FINALIZE_BASKET_START,
      payload: {
        loading: true,
        orderLoading: true,
      },
    });

    try {
      await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id,
            buyer,
            reference,
          },
        },
      });

      const cancelUrl =
        getWindow().origin +
        urlPrefix('/basket/psp/cancelled', { locale, countryCode, currency });

      const failUrl =
        getWindow().origin +
        urlPrefix('/basket/psp/failed', { locale, countryCode, currency });

      const orderRedirectPath = `/order/{publicOrderId}/psp/${id}`;

      const {
        data: {
          baskets: {
            finalizeBasket: { publicOrderId, nextStep, paymentWindowUrl },
          },
        },
      } = await client.mutate({
        mutation: mutationFinalizeBasket,
        variables: {
          input: {
            basketId: id,
            paymentMethod,
            countryCode,
            redirectConfig: {
              okUrl: `${
                getWindow().origin
                // Can't use urlPrefix here as {publicOrderId} is case sensitive
              }/${locale}/${countryCode}/${currency}${orderRedirectPath}`,
              cancelUrl,
              failUrl,
            },
          },
        },
      });

      dispatch({
        type: FINALIZE_BASKET_SUCCESS,
        payload: {
          loading: false,
        },
      });

      if (['ShowConfirmation', 'ShowConfirmationPage'].includes(nextStep)) {
        await dispatch(
          redirectTo({ pathname: `/order/${publicOrderId}/psp/${id}` }),
        );
      } else if (paymentWindowUrl.includes(valitorString)) {
        window.location.replace(`${paymentWindowUrl}?lang=${locale}`);
      } else {
        window.location.replace(`${paymentWindowUrl}`);
      }
    } catch (error) {
      dispatch({
        type: FINALIZE_BASKET_ERROR,
        error,
        payload: {
          loading: false,
          orderLoading: false,
        },
      });
      showError(getFromState(getState(), 'error'));
    }
    return getFromState(getState(), 'basket');
  };
}

export function setShippingQuote(basketId, deliveryId, shippingQuoteId) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_SHIPPINGQUOTE_START,
      payload: {
        shippingQuoteLoading: true,
      },
    });

    try {
      const {
        data: {
          baskets: { updateBasket },
        },
      } = await client.mutate({
        mutation: mutationUpdateBasket,
        variables: {
          input: {
            id: basketId,
            setShippingQuotes: [
              {
                deliveryId,
                shippingQuoteId,
              },
            ],
          },
        },
      });

      dispatch({
        type: SET_SHIPPINGQUOTE_SUCCESS,
        payload: {
          shippingQuoteLoading: false,
          ...updateBasket,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_SHIPPINGQUOTE_ERROR,
        error,
        payload: {
          shippingQuoteLoading: false,
          shippingQuoteError: error,
        },
      });
      showError(getFromState(getState(), 'error'));
    }
    return getFromState(getState(), 'basket');
  };
}

export function getOrder(orderId) {
  return async (dispatch, getState, { client }) => {
    const {
      intl: { locale },
    } = getState();
    dispatch({
      type: GET_ORDER_START,
      payload: {
        loading: true,
        orderLoading: true,
      },
    });

    try {
      const {
        data: {
          orders: { orderByPublicId = {} },
        },
        loading,
      } = await pollUntilDone(
        () =>
          client.query({
            query: getOrderQuery,
            variables: {
              id: orderId,
            },
          }),
        ORDER_CREATION_POLL_INTERVAL,
        ORDER_CREATION_TIMEOUT,
      );

      const {
        deliveries,
        salesChannel: { id },
      } = orderByPublicId;
      const productIds = [];
      deliveries?.forEach(({ productLines }) => {
        productLines.forEach(({ productId }) => {
          if (!productIds.includes(productId)) productIds.push(productId);
        });
      });
      let presentations = [];
      if (productIds?.length) {
        const {
          data: { productsBySalesChannel },
        } = await client.query({
          query: getProductsQuery,
          variables: {
            input: {
              productIds,
              salesChannelId: id,
              expectedLanguage: locale,
              includePresentationKeys: ['productTitle', 'productLogo'],
            },
          },
          context: {
            service: 'cms',
            fetchPolicy: 'cache-first',
          },
        });
        presentations = productsBySalesChannel;
      }

      const {
        data: {
          orders: { getPaymentInfoByPublicId },
        },
      } = await client.query({
        query: getOrderPaymentInfoQuery,
        variables: {
          id: orderId,
        },
        context: {
          service: 'graphqlHost',
        },
      });

      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: {
          ...orderByPublicId,
          presentations,
          orderPaymentInfo: getPaymentInfoByPublicId,
          loading,
          orderLoading: false,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_ORDER_ERROR,
        error,
        payload: {
          error,
          loading: false,
          orderLoading: false,
        },
      });
      showError(getFromState(getState(), 'error'));
    }

    return getState() && getState().basket.order;
  };
}

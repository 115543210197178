import { defineMessages } from 'react-intl';

export const localeMessages = defineMessages({
  en: {
    id: 'locale.en',
    defaultMessage: 'English',
    description: '',
  },
  bg: {
    id: 'locale.bg',
    defaultMessage: 'Bulgarian',
    description: '',
  },
  cs: {
    id: 'locale.cs',
    defaultMessage: 'Czech',
    description: '',
  },
  da: {
    id: 'locale.da',
    defaultMessage: 'Danish',
    description: '',
  },
  de: {
    id: 'locale.de',
    defaultMessage: 'German',
    description: '',
  },
  el: {
    id: 'locale.el',
    defaultMessage: 'Greek',
    description: '',
  },
  es: {
    id: 'locale.es',
    defaultMessage: 'Spanish',
    description: '',
  },
  fi: {
    id: 'locale.fi',
    defaultMessage: 'Finnish',
    description: '',
  },
  fr: {
    id: 'locale.fr',
    defaultMessage: 'French',
    description: '',
  },
  hu: {
    id: 'locale.hu',
    defaultMessage: 'Hungarian',
    description: '',
  },
  it: {
    id: 'locale.it',
    defaultMessage: 'Italian',
    description: '',
  },
  ja: {
    id: 'locale.ja',
    defaultMessage: 'Japanese',
    description: '',
  },
  nl: {
    id: 'locale.nl',
    defaultMessage: 'Dutch',
    description: '',
  },
  no: {
    id: 'locale.no',
    defaultMessage: 'Norwegian',
    description: '',
  },
  pl: {
    id: 'locale.pl',
    defaultMessage: 'Polish',
    description: '',
  },
  pt: {
    id: 'locale.pt',
    defaultMessage: 'Portugese',
    description: '',
  },
  ro: {
    id: 'locale.ro',
    defaultMessage: 'Romanian',
    description: '',
  },
  ru: {
    id: 'locale.ru',
    defaultMessage: 'Russian',
    description: '',
  },
  sk: {
    id: 'locale.sk',
    defaultMessage: 'Slovak',
    description: '',
  },
  sl: {
    id: 'locale.sl',
    defaultMessage: 'Slovenian',
    description: '',
  },
  sv: {
    id: 'locale.sv',
    defaultMessage: 'Swedish',
    description: '',
  },
  tr: {
    id: 'locale.tr',
    defaultMessage: 'Turkish',
    description: '',
  },
});

export const headerMessages = defineMessages({
  business: {
    id: 'header.business',
    defaultMessage: 'Login to Business',
    description: 'Business link in header',
  },
  businessButtonLink: {
    id: 'business.button.link',
    defaultMessage: '/b2b',
    description: 'Business button link',
  },
  global: {
    id: 'header.global',
    defaultMessage: 'Visit Global',
    description: 'Global page link in header',
  },
  redeemGiftCard: {
    id: 'header.redeemGiftCard',
    defaultMessage: 'Redeem Gift Card',
    description: '',
  },
  redeemSuperGiftCard: {
    id: 'header.redeemSuperGiftCard',
    defaultMessage: 'Redeem Global Gift Card',
    description: 'Header submenu item',
  },
  redeemLocalSuperGiftCard: {
    id: 'header.redeemLocalSuperGiftCard',
    defaultMessage: 'Redeem Local Supergiftcard',
    description: 'Header submenu item',
  },
  buySuperGiftCard: {
    id: 'header.buySuperGiftCard',
    defaultMessage: 'Buy Global Gift Card',
    description: 'Header submenu item',
  },
  buyLocalSuperGiftCard: {
    id: 'header.buyLocalSuperGiftCard',
    defaultMessage: 'Buy Local Supergiftcard',
    description: 'Header submenu item',
  },
  seeAllGiftcards: {
    id: 'header.seeAllGiftcards',
    defaultMessage: 'See all giftcards',
    description: '',
  },
  globalGiftCard: {
    id: 'header.globalGiftCard',
    defaultMessage: 'Global Gift Card',
    description: '',
  },
  localGlobalGiftCard: {
    id: 'header.localGlobalGiftCard',
    defaultMessage: 'Local Supergiftcard',
    description: '',
  },
  categories: {
    id: 'header.categories',
    defaultMessage: 'Categories',
    description: '',
  },
  seeAllCategories: {
    id: 'header.seeAllCategories',
    defaultMessage: 'See All Categories',
    description: '',
  },
  occasions: {
    id: 'header.occasions',
    defaultMessage: 'Occasions',
    description: '',
  },
  seeAllOccasions: {
    id: 'header.seeAllOccasions',
    defaultMessage: 'See All Occasions',
    description: '',
  },
  brands: {
    id: 'header.brands',
    defaultMessage: 'Brands',
    description: '',
  },
  seeAllBrands: {
    id: 'header.seeAllBrands',
    defaultMessage: 'See All Brands',
    description: '',
  },
  about: {
    id: 'header.about',
    defaultMessage: 'About',
    description: '',
  },
  contact: {
    id: 'header.contact',
    defaultMessage: 'Contact',
    description: '',
  },
  help: {
    id: 'header.help',
    defaultMessage: 'Help',
    description: '',
  },
  termsAndConditions: {
    id: 'header.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
    description: '',
  },
  privacyPolicy: {
    id: 'header.privacyPolicy',
    defaultMessage: 'Privacy policy',
    description: '',
  },
  cookiePolicy: {
    id: 'header.cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: '',
  },
  usp1: {
    id: 'header.usp1',
    defaultMessage: "DENMARK'S LARGEST GIFT PORTAL",
    description: '',
  },
  usp2: {
    id: 'header.usp2',
    defaultMessage: 'GRATIS personlig hilsen',
    description: '',
  },
  usp3: {
    id: 'header.usp3',
    defaultMessage: 'HURTIG levering',
    description: '',
  },
  usp4: {
    id: 'header.usp4',
    defaultMessage: 'VI ER e-market',
    description: '',
  },
});

export const searchMessages = defineMessages({
  searchLabel: {
    id: 'search.label',
    defaultMessage: 'Search',
    description: 'Label for search input in header',
  },
  searchPlaceholder: {
    id: 'search.placeholder',
    defaultMessage: 'Search for products, brands and categories',
    description: 'Placeholder for search input in header',
  },
  products: {
    id: 'search.productsLabel',
    defaultMessage: 'Products',
    description: 'Label for products in search results list',
  },
  productNoResults: {
    id: 'search.productNoResults',
    defaultMessage: `We don't seem to have a product like that. Maybe you will like one of these popular products?`,
    description: 'Message for when search query does not match any products',
  },
  brands: {
    id: 'search.brandsLabel',
    defaultMessage: 'Brands',
    description: 'Label for brands in search results list',
  },
  brandNoResults: {
    id: 'search.brandNoResults',
    defaultMessage: `No brands matches your query`,
    description: 'Message for when search query does not match any brands',
  },
  categories: {
    id: 'search.categoriesLabel',
    defaultMessage: 'Categories',
    description: 'Label for categories in search results list',
  },
  categoryNoResults: {
    id: 'search.categoryNoResults',
    defaultMessage: `No results for categories`,
    description: 'Message for when search query does not match any categories',
  },
  fromPrice: {
    id: 'search.fromPrice',
    defaultMessage: 'From',
    description: 'From price value',
  },
  altProductLogo: {
    id: 'search.altProductLogo',
    defaultMessage: '{title} product logo',
    description: 'Alt text for product logos in search results',
  },
});

export const filtersMessages = defineMessages({
  filters: {
    id: 'filters.filters',
    defaultMessage: 'FILTERS',
    description: 'Filter title',
  },
  reset: {
    id: 'filters.reset',
    defaultMessage: 'Reset',
    description: '',
  },
  showResults: {
    id: 'filters.showResults',
    defaultMessage: 'Show results',
    description: '',
  },
  selected: {
    id: 'filters.selected',
    defaultMessage: 'selected',
    description: '',
  },
  priceInterval: {
    id: 'filters.priceInterval',
    defaultMessage: 'Price interval',
    description: 'Filter title for product price interval',
  },
  type: {
    id: 'filters.type',
    defaultMessage: 'See all',
    description: 'Filter title for product type',
  },
  recipient: {
    id: 'filters.recipient',
    defaultMessage: 'Recipient',
    description: 'Filter title for recipient',
  },
  category: {
    id: 'filters.category',
    defaultMessage: 'Category',
    description: 'Filter title for category',
  },
  occasion: {
    id: 'filters.occasion',
    defaultMessage: 'Occasion',
    description: 'Filter title for occasion',
  },
  brand: {
    id: 'filters.brand',
    defaultMessage: 'Brand',
    description: 'Filter title for brand',
  },
  physicalProduct: {
    id: 'filters.physicalProduct',
    defaultMessage: 'Products',
    description: '',
  },
  giftCard: {
    id: 'filters.giftCard',
    defaultMessage: 'Giftcards',
    description: '',
  },
});

// To page presentation keys
export const homeMessages = defineMessages({
  tagline: {
    id: 'home.tagline',
    defaultMessage: 'Denmarks largest gift portal',
    description: 'Home page tagline',
  },
  linkSuperGiftcard: {
    id: 'home.linkSuperGiftcard',
    defaultMessage: 'BUY SUPERGIFTCARD',
    description: 'Home page Buy SuperGiftcard',
  },
  linkCountrySuperGiftcard: {
    id: 'home.linkCountrySuperGiftcard',
    defaultMessage: 'BUY LOCAL SUPERGIFTCARD',
    description: 'Home page Buy Local SuperGiftcard',
  },
  redeemSuperGiftcard: {
    id: 'home.linkRedeemSuperGiftcard',
    defaultMessage: 'REDEEM SUPERGIFTCARD',
    description: 'Home page Redeem SuperGiftcard',
  },
  seeAll: {
    id: 'home.linkSeeAllGifts',
    defaultMessage: 'SEE ALL GIFTS',
    description: 'Home page see all gifts',
  },
  linkSuperGiftcardDesc: {
    id: 'home.linkSuperGiftcardDesc',
    defaultMessage: 'Gives access to everyting on gogift.com',
    description: 'Home page Buy SuperGiftcard description',
  },
  linkCountrySuperGiftcardDesc: {
    id: 'home.linkCountrySuperGiftcardDesc',
    defaultMessage: 'Gives access to everyting on local gogift.com',
    description: 'Home page Buy Local SuperGiftcard description',
  },
  redeemSuperGiftcardDesc: {
    id: 'home.redeemSuperGiftcardDesc',
    defaultMessage: 'You can redeem your SuperGiftcard here',
    description: 'Home page Redeem SuperGiftcard description',
  },
  seeAllDesc: {
    id: 'home.seeAllDesc',
    defaultMessage:
      'Choose between thousands of products experiences and gift cards.',
    description: 'Home page see all gifts description',
  },
  linkForHim: {
    id: 'home.linkForHim',
    defaultMessage: 'For Him',
    description: 'Home page for him',
  },
  linkForHer: {
    id: 'home.linkForHer',
    defaultMessage: 'For Her',
    description: 'Home page for her',
  },
  linkForCouples: {
    id: 'home.linkForCouples',
    defaultMessage: 'For Couples',
    description: 'Home page for couples',
  },
  linkForKids: {
    id: 'home.linkForKids',
    defaultMessage: 'For Kids',
    description: 'Home page for kids',
  },
  shopAll: {
    id: 'home.shopAll',
    defaultMessage: 'Shop all',
    description: 'Home shop all',
  },
  popularGifts: {
    id: 'home.popularGifts',
    defaultMessage: 'POPULAR GIFTS',
    description: 'Home popular gifts',
  },
  popularGiftsLong: {
    id: 'home.popularGiftsLong',
    defaultMessage: 'A selection of our popular gifts!',
    description: 'Home popular gifts long',
  },
  seeAllGiftcards: {
    id: 'home.seeAllGiftcards',
    defaultMessage: 'See all gift cards',
    description: 'Home popular see all gift cards',
  },
  shopAfterOccasion: {
    id: 'home.shopAfterOccasion',
    defaultMessage: 'Shop after occasion',
    description: 'Home Shop after occasion',
  },
  seeAllOccasions: {
    id: 'home.seeAllOccasions',
    defaultMessage: 'See all occasions',
    description: 'Hme See all occasions',
  },
});

export const occasionMessages = defineMessages({
  selectionOfOurGifts: {
    id: 'occasion.selectionOfOurGifts',
    defaultMessage: 'A SELECTION OF OUR GIFTS',
    description: 'A SELECTION OF OUR GIFTS',
  },
  seeAll: {
    id: 'occasion.seeAll',
    defaultMessage: 'See all',
    description: '',
  },
});

// To page presentation keys
export const redeemMessages = defineMessages({
  redeemTitle: {
    id: 'redeem.redeemTitle',
    defaultMessage: 'Redeem SuperGiftcard',
    description: 'Title on Redeem page',
  },
  subtitle: {
    id: 'redeem.subtitle',
    defaultMessage:
      'Register your code to enter the redeem site and choose your gift.',
    description: 'Subtitle on Redeem page',
  },
  register: {
    id: 'redeem.register',
    defaultMessage: 'Register',
    description: '',
  },
  voucherCode: {
    id: 'redeem.voucherCode',
    defaultMessage: 'Voucher code',
    description: '',
  },
  voucherCodePlaceholder: {
    id: 'redeem.voucherCodePlaceholder',
    defaultMessage: 'Insert voucher code',
    description: '',
  },
  pinCode: {
    id: 'redeem.pinCode',
    defaultMessage: 'Pin Code',
    description: '',
  },
  pinCodePlaceholder: {
    id: 'redeem.pinCodePlaceholder',
    defaultMessage: 'Insert pin code',
    description: '',
  },
  redeemCongratulation: {
    id: 'redeem.redeemCongratulation',
    defaultMessage: 'Congratulation on your SuperGiftcard!',
    description: '',
  },
  redeemSubtitle: {
    id: 'redeem.redeemSubtitle',
    defaultMessage:
      'You can redeem your SuperGiftcard, at once or over multiple times. Choose freely between all our <strong>products</strong>, <strong>gift cards</strong> and <strong>experiences</strong> you can even combine the three.',
    description: '',
  },
  redeemBalance: {
    id: 'redeem.redeemBalance',
    defaultMessage: 'Your Balance',
    description: '',
  },
  redeemPickAndChoose: {
    id: 'redeem.redeemPickAndChoose',
    defaultMessage: 'Pick and choose your gift(s) below',
    description: '',
  },
  redeemOverviewTitle: {
    id: 'redeem.redeemOverviewTitle',
    defaultMessage: 'Your Redemption Overview',
    description: '',
  },
  redeemOverviewAddCode: {
    id: 'redeem.redeemOverviewAddCode',
    defaultMessage: 'Add code',
    description: '',
  },
  redeemOverviewCodeAdded: {
    id: 'redeem.redeemOverviewCodeAdded',
    defaultMessage: 'Code added',
    description: '',
  },
  redeemOverviewRegisteredCodes: {
    id: 'redeem.redeemOverviewRegisteredCodes',
    defaultMessage: 'Your registered codes',
    description: '',
  },
  redeemOverviewExpiry: {
    id: 'redeem.redeemOverviewExpiry',
    defaultMessage: 'Expiry',
    description: '',
  },
  redeemOverviewRemove: {
    id: 'redeem.redeemOverviewRemove',
    defaultMessage: 'Remove',
    description: '',
  },
  redeemOverviewTellMeMore: {
    id: 'redeem.redeemOverviewTellMeMore',
    defaultMessage: 'Tell me more!',
    description: '',
  },
  redeemOverviewIUnderstand: {
    id: 'redeem.redeemOverviewIUnderstand',
    defaultMessage: 'I understand!',
    description: '',
  },
  basketAddRedeem: {
    id: 'redeem.basketAddRedeem',
    defaultMessage: 'Add voucher code',
    description: 'Button label for adding more vouchers on Basket page',
  },
  backToHome: {
    id: 'redeem.redeemGoBack',
    defaultMessage: 'Go back',
    description: '',
  },
  checkYourLocation: {
    id: 'redeem.checkYourLocation',
    defaultMessage: 'Please check that your location is correct:',
    description: 'Checking if the client has correct country selected',
  },
  checkLocationConfirm: {
    id: 'redeem.checkLocationConfirm',
    defaultMessage: 'Confirm',
    description: 'Button used to confirm selected country',
  },
});

// To page presentation keys
export const shopMessages = defineMessages({
  loadMore: {
    id: 'shop.loadMore',
    defaultMessage: 'Load more',
    description: '',
  },
  sortBy: {
    id: 'shop.sortBy',
    defaultMessage: 'Sort By',
    description: '',
  },
  noProducts: {
    id: 'shop.noProducts',
    defaultMessage: 'No Products',
    description: '',
  },
  from: {
    id: 'shop.from',
    defaultMessage: 'From',
    description: '',
  },
  yourPrice: {
    id: 'shop.yourPrice',
    defaultMessage: 'Your Price',
    description: '',
  },
  retailPrice: {
    id: 'shop.retailPrice',
    defaultMessage: 'Retail price',
    description: '',
  },
  sortingPosition: {
    id: 'shop.sortingPosition',
    defaultMessage: 'Popularity',
    description: '',
  },
  alphabetic: {
    id: 'shop.alphabetic',
    defaultMessage: 'A-a',
    description: '',
  },
  sortingLowToHighPrice: {
    id: 'shop.sortingLowToHighPrice',
    defaultMessage: 'Price - Low to High',
    description: '',
  },
  sortingHighToLowPrice: {
    id: 'shop.sortingHighToLowPrice',
    defaultMessage: 'Price - High to Low',
    description: '',
  },
  notInStock: {
    id: 'shop.notInStock',
    defaultMessage: 'Not in stock',
    description: '',
  },
  inStock: {
    id: 'shop.inStock',
    defaultMessage: 'In stock',
    description: '',
  },
  of: {
    id: 'shop.of',
    defaultMessage: 'of',
    description: 'For product counter "1 of 1"',
  },
});

export const footerMessages = defineMessages({
  aboutGoGift: {
    id: 'footer.aboutGoGift',
    defaultMessage: 'About GoGift',
    description: '',
  },
  termsAndConditions: {
    id: 'header.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
    description: '',
  },
  privacyPolicy: {
    id: 'header.privacyPolicy',
    defaultMessage: 'Privacy policy',
    description: '',
  },
  cookiePolicy: {
    id: 'header.cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: '',
  },
  contact: {
    id: 'footer.contact',
    defaultMessage: 'Contact',
    description: '',
  },
  help: {
    id: 'footer.help',
    defaultMessage: 'Help',
    description: '',
  },
  helpButtonLink: {
    id: 'footer.help.link',
    defaultMessage: '/faq',
    description: ' ',
  },
  personal: {
    id: 'footer.personal',
    defaultMessage: 'Personal',
    description: '',
  },
  business: {
    id: 'footer.business',
    defaultMessage: 'Business',
    description: '',
  },
  partner: {
    id: 'footer.partner',
    defaultMessage: 'Partner',
    description: '',
  },
  identity: {
    id: 'footer.identity',
    defaultMessage:
      'GoGift is an innovative gifting company with a global reach. GoGift is owned by Egmont – a leading media group with activities in 30 countries.',
    description: '',
  },
  mail: {
    id: 'footer.mail',
    defaultMessage: 'Mail',
    description: '',
  },
  tel: {
    id: 'footer.tel',
    defaultMessage: 'Tel',
    description: '',
  },
  days: {
    id: 'footer.days',
    defaultMessage: 'Mon-Fri',
    description: '',
  },
  language: {
    id: 'footer.language',
    defaultMessage: 'Language',
    description: '',
  },
  languageHelp: {
    id: 'footer.languageHelp',
    defaultMessage: 'Which language do you wish to use?',
    description: '',
  },
  country: {
    id: 'footer.country',
    defaultMessage: 'Country',
    description: '',
  },
  countryHelp: {
    id: 'footer.countryHelp',
    defaultMessage: 'Which country do you wish to view?',
    description: '',
  },
  currency: {
    id: 'footer.currency',
    defaultMessage: 'Currency',
    description: '',
  },
  currencyHelp: {
    id: 'footer.currencyHelp',
    defaultMessage: 'Select desired currency',
    description: '',
  },
  cta: {
    id: 'footer.cta',
    defaultMessage: 'Global Gift Card',
    description: 'Mobile footer Global Giftcard button',
  },
  redeemGiftCard: {
    id: 'footer.redeemGiftCard',
    defaultMessage: 'Redeem Giftcard',
    description: 'Mobile footer Redeem button',
  },
  changeLocation: {
    id: 'footer.changeLocation',
    defaultMessage: 'Change location',
    description: 'Label for Globalization menu on mobile',
  },
  manageCookieConsent: {
    id: 'footer.manageCookieConsent',
    defaultMessage: 'Change cookie consent',
    description: 'Label for link to cookie consent management dialog',
  },
});

export const greetingMessages = defineMessages({
  greetingNotFound: {
    id: 'greeting.notFound',
    defaultMessage: 'Greeting not found',
    description: '',
  },
  greetingNotFoundDescription: {
    id: 'greeting.notFoundDescription',
    defaultMessage:
      'Sorry, the greeting you were trying to view does not exist.',
    description: '',
  },
  printGreeting: {
    id: 'greeting.printGreeting',
    defaultMessage: 'Print greeting',
    description: '',
  },
});

export const addGreetingMessages = defineMessages({
  cancel: {
    id: 'addGreeting.cancel',
    defaultMessage: 'Cancel',
    description: '',
  },
  removeGreeting: {
    id: 'addGreeting.removeGreeting',
    defaultMessage: 'Remove greeting',
    description: '',
  },
  saveAndContinue: {
    id: 'addGreeting.saveAndContinue',
    defaultMessage: 'Save & continue',
    description: '',
  },
  images: {
    id: 'addGreeting.images',
    defaultMessage: 'Images',
    description: '',
  },
  uploadImage: {
    id: 'addGreeting.uploadImage',
    defaultMessage: 'Upload image (max 5MB)',
    description: '',
  },
  video: {
    id: 'addGreeting.video',
    defaultMessage: 'Video',
    description: '',
  },
  frontOfCard: {
    id: 'addGreeting.frontOfCard',
    defaultMessage: 'Front of card',
    description: '',
  },
  backOfCard: {
    id: 'addGreeting.backOfCard',
    defaultMessage: 'Back of card',
    description: '',
  },
  typeYourMessageHere: {
    id: 'addGreeting.typeYourMessageHere',
    defaultMessage: 'Type your message here',
    description: '',
  },
  allowedImageTypes: {
    id: 'addGreeting.allowedImageTypes',
    defaultMessage: 'Valid images are jpg and png',
    description: '',
  },
  allowedFileSize: {
    id: 'addGreeting.allowedFileSize',
    defaultMessage: 'Max image size is 5MB',
    description: '',
  },
  fileUploadError: {
    id: 'addGreeting.fileUploadError',
    defaultMessage: 'Could not read uploaded image',
    description: '',
  },
  fileReadError: {
    id: 'addGreeting.fileReadError',
    defaultMessage: 'Could not read image from external provider',
    description: '',
  },
  unsupportedCharacter: {
    id: 'addGreeting.unsupportedCharacter',
    defaultMessage: "We don't support these characters yet",
    description: '',
  },
});

export const addWrappingMessages = defineMessages({
  continue: {
    id: 'wrapping.continue',
    defaultMessage: 'Continue',
    description: '',
  },
  removalOfwrappings: {
    id: 'wrapping.removalOfwrappings',
    defaultMessage:
      'If you change delivery method your wrappings will be lost.',
    description: '',
  },
  removeWrappingsTitle: {
    id: 'wrapping.removeWrappingsTitle',
    defaultMessage: 'Your wrappings will be lost.',
    description: '',
  },
  confirmation: {
    id: 'wrapping.confirmation',
    defaultMessage: 'Are you sure you want to continue?',
    description: '',
  },
});

// To page presentation keys
export const basketMessages = defineMessages({
  orderSummary: {
    id: 'basket.orderSummary',
    defaultMessage: 'Order Summary',
    description: '',
  },
  paymentExchangeRate: {
    id: 'basket.paymentExchangeRate',
    defaultMessage: 'Payment exchange rate',
    description: '',
  },
  agreeToTerms: {
    id: 'basket.agreeToTerms',
    defaultMessage: 'Agree to ',
    description: '',
  },
  gogiftsTerms: {
    id: 'basket.gogiftsTerms',
    defaultMessage: 'GoGift’s terms',
    description: '',
  },
  date_asap: {
    id: 'basket.date_asap',
    defaultMessage: 'Delivered as soon as possible',
    description: '',
  },
  shipping: {
    id: 'basket.shipping',
    defaultMessage: 'Shipping',
    description: '',
  },
  superGiftcardFee: {
    id: 'basket.superGiftcardFee',
    defaultMessage: 'SuperGiftcard Fee',
    description: '',
  },
  giftwrapping: {
    id: 'basket.giftwrapping',
    defaultMessage: 'Gift wrapping',
    description: '',
  },
  personalizedGreeting: {
    id: 'basket.personalizedGreeting',
    defaultMessage: 'Personalized greeting',
    description: '',
  },
  totalItems: {
    id: 'basket.itemsInBasket',
    defaultMessage:
      '{count, plural, =0 {You have no items in your basket} one {You have # item in your basket} other {You have # items in your basket}}',
    description: '',
  },
  subtotalItems: {
    id: 'basket.subtotalItems',
    defaultMessage:
      '{count, plural, =0 {no items} one {Subtotal (# item)} other {Subtotal (# items)}}',
    description: '',
  },
  total: {
    id: 'basket.total',
    defaultMessage: 'Total',
    description: '',
  },
  subtotal: {
    id: 'basket.subtotal',
    defaultMessage: 'Subtotal',
    description: '',
  },
  product: {
    id: 'basket.product',
    defaultMessage: 'Product',
    description: '',
  },
  delivery: {
    id: 'basket.delivery',
    defaultMessage: 'Delivery',
    description: '',
  },
  physicalProductDelivery: {
    id: 'basket.physicalProductDelivery',
    defaultMessage: 'Choose delivery option in <b>next step</b>',
    description: 'Delivery info text on physical product basket item',
  },
  chooseDeliveryOption: {
    id: 'basket.chooseDeliveryOption',
    defaultMessage: 'Choose delivery option',
    description:
      'Text for button in basket if basket contains physical products',
  },
  deliveryBy: {
    id: 'basket.deliveryBy',
    defaultMessage: 'Delivery by',
    description: '',
  },
  quantity: {
    id: 'basket.quantity',
    defaultMessage: 'Quantity',
    description: '',
  },
  value: {
    id: 'basket.value',
    defaultMessage: 'Value',
    description: '',
  },
  price: {
    id: 'basket.price',
    defaultMessage: 'Price',
    description: '',
  },
  edit: {
    id: 'basket.edit',
    defaultMessage: 'Edit',
    description: '',
  },
  add: {
    id: 'basket.add',
    defaultMessage: 'Add',
    description: '',
  },
  delete: {
    id: 'basket.delete',
    defaultMessage: 'Remove',
    description: '',
  },
  update: {
    id: 'basket.update',
    defaultMessage: 'Update',
    description: '',
  },
  b2bUserAddress: {
    id: 'basket.b2bUserAddress',
    defaultMessage: 'Change your delivery address on your',
    description: '',
  },
  b2bUserAddressLink: {
    id: 'basket.b2bUserAddressLink',
    defaultMessage: 'account page',
    description: '',
  },
  treatPersonalData: {
    id: 'basket.treatPersonalData',
    defaultMessage: 'Read more about how we treat your personal data ',
    description: '',
  },
  here: {
    id: 'basket.here',
    defaultMessage: 'here',
    description: '',
  },
  billingInformation: {
    id: 'basket.billingInformation',
    defaultMessage: 'Billing Information',
    description: '',
  },
  termsAndConditions: {
    id: 'basket.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
    description: '',
  },
  listItem1: {
    id: 'basket.listItem1',
    defaultMessage: 'You have 14 days exchange',
    description: '',
  },
  listItem2: {
    id: 'basket.listItem2',
    defaultMessage:
      'If you pay with credit card, you are secured against misuse',
    description: '',
  },
  readTerms: {
    id: 'basket.readTerms',
    defaultMessage: 'Read the terms and conditions',
    description: '',
  },
  copyFromRecipientInformation: {
    id: 'basket.copyFromRecipientInformation',
    defaultMessage: 'Copy from recipient information',
    description: '',
  },
  shippingQuoteLoading: {
    id: 'basket.shippingQuoteLoading',
    defaultMessage: 'Calculating shipping costs...',
    description: 'Text to display when waiting for shipping quote update',
  },
  continuePayment: {
    id: 'basket.continuePayment',
    defaultMessage: 'Continue to Payment',
    description: '',
  },
  continuePaymentRedeem: {
    id: 'basket.continuePaymentRedeem',
    defaultMessage: 'Redeem',
    description: 'Redeem items in basket primary action button',
  },
  continueShopping: {
    id: 'basket.continueShopping',
    defaultMessage: 'Continue Shopping',
    description: '',
  },
  continueCheckout: {
    id: 'basket.continueCheckout',
    defaultMessage: 'Continue to Checkout',
    description: '',
  },
  editDeliveryType: {
    id: 'basket.editDeliveryType',
    defaultMessage: 'Edit your delivery type & address',
    description: '',
  },
  stayInContact: {
    id: 'basket.stayInContact',
    defaultMessage: 'Lets stay in contact!',
    description: '',
  },
  receiveNewsletter: {
    id: 'basket.receiveNewsletter',
    defaultMessage: 'Yes, I would like to receive GoGifts newsletter',
    description: '',
  },
  choosePayment: {
    id: 'basket.choosePayment',
    defaultMessage: 'Choose payment method:',
    description: '',
  },
  invoicePayment: {
    id: 'basket.invoicePayment',
    defaultMessage: 'Invoice payment',
    description: '',
  },
  paymentServiceProvider: {
    id: 'basket.paymentServiceProvider',
    defaultMessage: 'Payment service provider',
    description: '',
  },
  trustly: {
    id: 'basket.trustly',
    defaultMessage: 'Trustly',
    description: '',
  },
  adyen: {
    id: 'basket.adyen',
    defaultMessage: 'Adyen',
    description: '',
  },
  redeemButton: {
    id: 'basket.redeemButton',
    defaultMessage: 'Redeem SuperGiftcard',
    description: '',
  },
  amountRemaining: {
    id: 'basket.amountRemaining',
    defaultMessage: 'Amount remaining',
    description: '',
  },
  includingTax: {
    id: 'basket.includingTax',
    defaultMessage: '(including tax)',
    description:
      'displayed when tax is applied to something in the basket e.g. a fee or a giftcard',
  },
  paymentHelpText: {
    id: 'basket.paymentHelpText',
    defaultMessage: 'You are not approved for invoice',
    description: 'Displayed when Invoice payment are disabled',
  },
  orderReference: {
    id: 'basket.orderReference',
    defaultMessage: 'Provide your order reference:',
    description: '',
  },
  orderReferenceRequirement: {
    id: 'basket.orderReferenceRequirement',
    defaultMessage:
      'Fill out this field if you need to apply an order reference to your order',
    description: '',
  },
  selectNewAmount: {
    id: 'basket.selectNewAmount',
    defaultMessage:
      'unfortunately we dont have the selected amount for this delivery method. Please select new amount:',
    description:
      'Message displayed in Edit Delivery modal when user has changed a delivery method that does not have a matching SKU',
  },
  confirmRemovingBasketTitle: {
    id: 'basket.confirmRemovingBasketTitle',
    defaultMessage: 'Are you sure you want to proceed to selected country?',
    description: '',
  },
  confirmRemovingBasket: {
    id: 'basket.confirmRemovingBasket',
    defaultMessage:
      'We can see that you still have products in your basket. All products will be lost if you change country. Are you sure you want to proceed?',
    description: '',
  },
  yes: {
    id: 'basket.yes',
    defaultMessage: 'yes',
    description: '',
  },
  cancel: {
    id: 'basket.cancel',
    defaultMessage: 'Cancel',
    description: '',
  },
});

export const pickupPointMessages = defineMessages({
  title: {
    id: 'pickupPoint.title',
    defaultMessage: 'Where do you want your gifts delivered?',
    description: 'Title on pickup point page',
  },
  basketLink: {
    id: 'pickupPoint.basketLink',
    defaultMessage: '< Return to basket',
    description: 'Back to basket link text',
  },
  addressDescription: {
    id: 'pickupPoint.addressDescription',
    defaultMessage:
      'Add your address to search for pickup points in proximity of you.',
    description: 'Address form description',
  },
  pickupPointDescription: {
    id: 'pickupPoint.pickupPointDescription',
    defaultMessage: 'Choose one of the available pickup points below.',
    description: 'Pickup point list description',
  },
  selectedAddressDescription: {
    id: 'pickupPoint.selectedAddressDescription',
    defaultMessage: 'Listing pickup points in proximity of:',
    description: '',
  },
  search: {
    id: 'pickupPoint.search',
    defaultMessage: 'Search',
    description: 'Pickup point search button label',
  },
  changeAddress: {
    id: 'pickupPoint.changeAddress',
    defaultMessage: 'Change address',
    description: 'Change address button label',
  },
  productListTitle: {
    id: 'pickupPoint.productListTitle',
    defaultMessage: 'These products will be delivered here',
    description: '',
  },
  productListInfo: {
    id: 'pickupPoint.productListInfo',
    defaultMessage:
      '<b>Attention:</b> This delivery option is only for physical gifts, not gift cards.',
    description: '',
  },
  noPickupPointsAvailable: {
    id: 'pickupPoint.noPickupPointsAvailable',
    defaultMessage:
      'No pickup points available near the selected address. Please change address and retry.',
    description: 'Error message shown when no pickup poinst are found',
  },
  continueRedeemOnly: {
    id: 'pickupPoint.continueRedeemOnly',
    defaultMessage: 'Place order',
    description: 'Button text for redeem-only customers',
  },
  continueRedeemAndPay: {
    id: 'pickupPoint.continueRedeemAndPay',
    defaultMessage: 'Choose payment',
    description: 'Button text for redeem-and-pay customers',
  },
  sameAddressAsBilling: {
    id: 'pickupPoint.sameAddressAsBilling',
    defaultMessage: 'Use the same address as billing information',
    description: 'Checkbox to select delivery address',
  },

  deliveryToPickupPoint: {
    id: 'pickupPoint.deliveryToPickupPoint',
    defaultMessage: 'Delivery to pickup point',
    description: 'Delivery pickup point',
  },
  homeDeliveryPoint: {
    id: 'pickupPoint.homeDeliveryPoint',
    defaultMessage: 'Delivery to home address',
    description: 'Home Delivery pickup point',
  },
});

// To page presentation keys
export const productMessages = defineMessages({
  addToCart: {
    id: 'product.addToCart',
    defaultMessage: 'Buy',
    description: 'Add to cart label',
  },
  addToCartRedeem: {
    id: 'product.addToCartRedeem',
    defaultMessage: 'Choose',
    description: 'Add to cart label in redeem mode',
  },
  from: {
    id: 'product.from',
    defaultMessage: 'From',
    description: 'From 50 GBP',
  },
  giftcardCanBeUsed: {
    id: 'product.giftcardCanBeUsed',
    defaultMessage: 'Giftcard can be used in',
    description: '',
  },
  giftcardCountries: {
    id: 'product.giftcardCountries',
    defaultMessage: '{number} countries',
    description: 'Shown when product can be used in more than 5 countries',
  },
  greetingCard: {
    id: 'product.greetingCard',
    defaultMessage: 'greetingCard',
    description: '',
  },
  greetingCardDescription: {
    id: 'product.greetingCardDescription',
    defaultMessage: 'Add text, images and video. Completely free.',
    description: '',
  },
  giftWrapping: {
    id: 'product.giftwrapping',
    defaultMessage: 'Gift wrapping',
    description: '',
  },
  giftWrappingDescription: {
    id: 'product.giftwrappingDescription',
    defaultMessage: 'Add a gift wrapping.',
    description: '',
  },
  giftWrappingDetails: {
    id: 'product.giftWrappingDetails',
    defaultMessage: 'price per envelope',
    description: '',
  },
  addToCard: {
    id: 'product.addToCard',
    defaultMessage: 'Add to Cart',
    description: '',
  },
  addedToCartModalTitle: {
    id: 'product.addedToCartModalTitle',
    defaultMessage: 'Your basket has been updated!',
    description: '',
  },
  addedToCartModalText: {
    id: 'product.addedToCartModalText',
    defaultMessage:
      'Go to basket to finalize your order, or continue shopping to choose more gifts.',
    description: '',
  },
  goToBasket: {
    id: 'product.goToBasket:',
    defaultMessage: 'Go to basket',
    description: '',
  },
  continueShopping: {
    id: 'product.continueShopping:',
    defaultMessage: 'Continue Shopping',
    description: '',
  },
  addWrappingTitle: {
    id: 'product.addWrappingTitle',
    defaultMessage: 'Add gift wrapping to your product',
    description: '',
  },
  createGreeting: {
    id: 'product.createGreeting',
    defaultMessage: 'Create greeting',
    description: '',
  },
  editGreeting: {
    id: 'product.editGreeting',
    defaultMessage: 'Change greeting',
    description: '',
  },
  productLongDescription: {
    id: 'product.productLongDescription',
    defaultMessage: 'Description',
    description: '',
  },
  altProductLogo: {
    id: 'product.altProductLogo',
    defaultMessage: '{title} product logo',
    description: 'Alt text for product logo',
  },
  productPostage: {
    id: 'product.productPostage',
    defaultMessage: 'Postage',
    description: '',
  },
  productPayment: {
    id: 'product.productPayment',
    defaultMessage: 'Payment',
    description: '',
  },
  productDelivery: {
    id: 'product.productDelivery',
    defaultMessage: 'Delivery',
    description: '',
  },
  deliverInSilver: {
    id: 'product.deliverInSilver',
    defaultMessage: 'Deliver you SuperGift card in a silver envelope',
    description: '',
  },
  disclaimerAgree: {
    id: 'product.disclaimerAgree',
    defaultMessage: 'I agree',
    description: '',
  },
  cancel: {
    id: 'product.cancel',
    defaultMessage: 'Cancel',
    description: '',
  },
  amountNotInStock: {
    id: 'product.amountNotInStock',
    defaultMessage: 'Not in stock',
    description: 'Amount not in stock',
  },
  productNotInStock: {
    id: 'product.productNotInStock',
    defaultMessage: 'This product is currently out of stock',
    description: 'Product not in stock',
  },
  chooseValue: {
    id: 'product.chooseValue',
    defaultMessage: 'Choose value',
    description: 'Drop down placeholder when no value is prefilled',
  },
});

export const formMessages = defineMessages({
  order: {
    id: 'form.order',
    defaultMessage: 'order',
    description: '',
  },
  delivery: {
    id: 'form.delivery',
    defaultMessage: 'delivery',
    description: '',
  },
  date: {
    id: 'form.date',
    defaultMessage: 'Date',
    description: '',
  },
  timezone: {
    id: 'form.timezone',
    defaultMessage: 'Timezone',
    description: '',
  },
  date_asap: {
    id: 'form.date_asap',
    defaultMessage: 'As soon as possible',
    description: '',
  },
  set_date: {
    id: 'form.set_date',
    defaultMessage: 'Set date and time',
    description: '',
  },
  past_date_error: {
    id: 'form.past_date_error',
    defaultMessage: 'Date should not be in the past',
    description: '',
  },
  asap_delivery_date_disclaimer: {
    id: 'form.asap_delivery_date_disclaimer',
    defaultMessage:
      'Your card will be delivered as soon as possible, based on the delivery method you selected.',
    description: '',
  },
  set_delivery_date_disclaimer: {
    id: 'form.set_delivery_date_disclaimer',
    defaultMessage:
      'Your card will be delivered based on specific date, time and timezone you selected. Make sure to set recipients local timezone to receive correct delivery time.',
    description: '',
  },
  placeholder_timezone: {
    id: 'form.placeholder_timezone',
    defaultMessage: 'Set timezone',
    description: '',
  },
  method: {
    id: 'form.method',
    defaultMessage: 'Method',
    description: '',
  },
  Sms: {
    id: 'form.sms',
    defaultMessage: 'SMS',
    description: '',
  },
  Email: {
    id: 'form.Email',
    defaultMessage: 'Email',
    description: '',
  },
  Physical: {
    id: 'form.physical',
    defaultMessage: 'Post',
    description: '',
  },
  CsvByEmail: {
    id: 'form.csvbyemail',
    defaultMessage: 'CSV',
    description: '',
  },
  amountAndQuantity: {
    id: 'form.amountAndQuantity',
    defaultMessage: 'amountAndQuantity',
    description: '',
  },
  amount: {
    id: 'form.amount',
    defaultMessage: 'Amount',
    description: '',
  },
  currency: {
    id: 'form.currency',
    defaultMessage: 'Currency',
    description: '',
  },
  selectAmount: {
    id: 'form.selectAmount',
    defaultMessage: 'Select amount',
    description: '',
  },
  quantity: {
    id: 'form.quantity',
    defaultMessage: 'Quantity',
    description: '',
  },
  recipient: {
    id: 'form.recipient',
    defaultMessage: 'recipient',
    description: '',
  },
  recipientDescription: {
    id: 'form.recipientDescription',
    defaultMessage: 'recipient decription',
    description: 'Recipient description',
  },
  fullName: {
    id: 'form.fullName',
    defaultMessage: 'Full name',
    description: '',
  },
  email: {
    id: 'form.email',
    defaultMessage: 'E-mail',
    description: '',
  },
  emailRepeat: {
    id: 'form.emailRepeat',
    defaultMessage: 'Repeat e-mail',
    description: 'Repeat e-mail description',
  },
  phoneNumber: {
    id: 'form.phoneNumber',
    defaultMessage: 'phoneNumber',
    description: '',
  },
  phoneNumberRepeat: {
    id: 'form.phoneNumberRepeat',
    defaultMessage: 'Repeat phone number',
    description: 'Repeat phone number description',
  },
  PhoneNotMatching: {
    id: 'form.PhoneNotMatching',
    defaultMessage: 'PhoneNotMatching',
    description: 'Error message for mismatched phone number inputs',
  },
  country: {
    id: 'form.country',
    defaultMessage: 'country',
    description: '',
  },
  city: {
    id: 'form.city',
    defaultMessage: 'city',
    description: '',
  },
  postCode: {
    id: 'form.postCode',
    defaultMessage: 'postCode',
    description: '',
  },
  address: {
    id: 'form.address',
    defaultMessage: 'address',
    description: '',
  },
  attention: {
    id: 'form.attention',
    defaultMessage: 'attention',
    description: '',
  },
  line1: {
    id: 'form.line1',
    defaultMessage: 'line1',
    description: '',
  },
  line2: {
    id: 'form.line2',
    defaultMessage: 'line2',
    description: '',
  },
  extras: {
    id: 'form.extras',
    defaultMessage: 'Extras',
    description: '',
  },
  required: {
    id: 'form.required',
    defaultMessage: 'required',
    description: 'Error message for empty required fields',
  },
  invalidName: {
    id: 'form.invalidName',
    defaultMessage: 'Name input should be alphabetic',
    description: 'Error message for invalid name input',
  },
  invalidPhoneNumber: {
    id: 'form.invalidPhoneNumber',
    defaultMessage: 'invalidPhoneNumber',
    description: 'Error message for invalid phone number input',
  },
  invalidEmail: {
    id: 'form.invalidEmail',
    defaultMessage: 'invalidEmail',
    description: 'Error message for invalid email input',
  },
  EmailNotMatching: {
    id: 'form.EmailNotMatching',
    defaultMessage: 'EmailNotMatching',
    description: 'Error message for mismatched email inputs',
  },
  invalidPostCode: {
    id: 'form.invalidPostCode',
    defaultMessage: 'invalidPostCode',
    description: 'Error message for invalid post code input',
  },
  physicalProductHelp: {
    id: 'form.physicalProductHelp',
    defaultMessage: 'You will finalize the delivery information in the basket',
    description:
      'inform the user that physical products delivery info will be finalized in the basket',
  },
});

// To page presentation keys
export const orderHistoryMessages = defineMessages({
  title: {
    id: 'orderHistory.title',
    defaultMessage: 'Order History',
    description: '',
  },
  orderTitle: {
    id: 'orderHistory.orderTitle',
    defaultMessage: 'Order {orderId}',
    description: 'Order detail page header',
  },
  orderId: {
    id: 'orderHistory.orderId',
    defaultMessage: 'Order id',
    description: '',
  },
  date: {
    id: 'orderHistory.date',
    defaultMessage: 'Date',
    description: '',
  },
  quantity: {
    id: 'orderHistory.quantity',
    defaultMessage: 'quantity',
    description: '',
  },
  total: {
    id: 'orderHistory.total',
    defaultMessage: 'total',
    description: '',
  },
  reference: {
    id: 'orderHistory.reference',
    defaultMessage: 'reference',
    description: '',
  },
  payment: {
    id: 'orderHistory.payment',
    defaultMessage: 'payment',
    description: '',
  },
  value: {
    id: 'orderHistory.value',
    defaultMessage: 'Value',
    description: 'Giftcard value',
  },
  deliveryMethod: {
    id: 'orderHistory.deliveryMethod',
    defaultMessage: 'Delivery method',
    description: 'Giftcard delivery method',
  },
  product: {
    id: 'orderHistory.product',
    defaultMessage: 'Product',
    description: 'Giftcard product',
  },
  orders: {
    id: 'orderHistory.orders',
    defaultMessage: 'Orders',
    description: '',
  },
  orderInfo: {
    id: 'orderHistory.orderInfo',
    defaultMessage:
      'Download the full order report to see all order lines. It is exported as an Excel document.',
    description: 'Order info below main order info on order details',
  },
  productsHeader: {
    id: 'orderHistory.productsHeader',
    defaultMessage: 'Products in Order',
    description: 'Product listing header for order details',
  },
  fees: {
    id: 'orderHistory.fees',
    defaultMessage: 'Fees',
    description: 'fees',
  },
});

export const notificationMessages = defineMessages({
  addStart: {
    id: 'notifications.addStart',
    defaultMessage: 'addStart',
    description: '',
  },
  addSuccess: {
    id: 'notifications.addSuccess',
    defaultMessage: 'addSuccess',
    description: '',
  },
  accountUpdateStart: {
    id: 'notifications.accountUpdateStart',
    defaultMessage: 'Updating account',
    description: 'Shown when a user tries to update their account',
  },
  accountUpdateSuccess: {
    id: 'notifications.accountUpdateSuccess',
    defaultMessage: 'Account updated',
    description: 'Shown when a users account update was successful',
  },
  accountCreateSuccess: {
    id: 'notifications.accountCreateSuccess',
    defaultMessage: 'Account created',
    description: '',
  },
  unexpectedError: {
    id: 'notifications.unexpectedError',
    defaultMessage: 'An unexpected error occured',
    description: 'Shown to a user when an error that cannot be handled occurs',
  },
  BadCreditCode: {
    id: 'notifications.BadCreditCode',
    defaultMessage: 'Not enough credit. Please contact customer support.',
    description:
      'Shown to a user when available credit limit would become negative',
  },
  InvalidSku: {
    id: 'notifications.InvalidSku',
    defaultMessage: 'Product is no longer available.',
    description:
      'Shown to a user when there a product is not available in the current sales channel or the product no longer exists',
  },
  OutOfStock: {
    id: 'notifications.OutOfStock',
    defaultMessage:
      'This product is out of stock and can not be added to your basket.',
    description: 'Show when a product added to basket is out of stock.',
  },
  FluentValidation: {
    id: 'notifications.FluentValidation',
    defaultMessage: 'Invalid',
    description: 'Shown next to input field not passing validation',
  },
  VoucherAdded: {
    id: 'notifications.VoucherAdded',
    defaultMessage: 'Voucher has already been added to your basket',
    description:
      'Shown in redeem/ portal when voucher has already been added to basket',
  },
  VoucherRedeemed: {
    id: 'notifications.VoucherRedeemed',
    defaultMessage: 'Voucher does not have any credit left',
    description:
      'Shown in redeem/ portal when voucher has already been redeemed',
  },
  VoucherNotFound: {
    id: 'notifications.VoucherNotFound',
    defaultMessage: 'Voucher does not exist',
    description: 'Shown in redeem/ portal when voucher is not found/ invalid',
  },
  InactiveVoucher: {
    id: 'notifications.InactiveVoucher',
    defaultMessage: 'Voucher is not active',
    description: 'Shown in redeem/ portal when voucher is credited, blocked.',
  },
  VoucherExpired: {
    id: 'notifications.VoucherExpired',
    defaultMessage: 'Voucher is past use date',
    decription: 'Shown in redeem/ portal when a voucher has expired',
  },
  AttemptToRedeemInWrongSalesChannel: {
    id: 'notifications.AttemptToRedeemInWrongSalesChannel',
    defaultMessage: "Voucher can't be used in this context",
    description:
      'Shown in redeem/ portal when voucher can only be redeemed in a specific sales channel',
  },
  giftcardUNAUTHORIZED_ACCESS: {
    id: 'notifications.giftcardUNAUTHORIZED_ACCESS',
    defaultMessage: 'Please contact support',
    decription: 'Too many downloads',
  },
  giftcardUNAUTHORIZED: {
    id: 'notifications.giftcardUNAUTHORIZED',
    defaultMessage: 'Invalid email or phone',
    decription: 'Invalid email or phone',
  },

  paymentCancelled: {
    id: 'notifications.paymentCancelled',
    defaultMessage: 'Payment cancelled',
    description: 'Shown when a psp payment is cancelled',
  },
  paymentFailed: {
    id: 'notifications.paymentFailed',
    defaultMessage: 'Payment failed',
    description: 'Shown when a psp payment is failed',
  },

  longRequest: {
    id: 'notifications.longRequest',
    defaultMessage: 'It`s taking longer then expected, please hold on...',
    description: '',
  },
  resendOrderConfirmationStart: {
    id: 'notifications.resendOrderConfirmationStart',
    defaultMessage: 'Sending order confirmation...',
    description: '',
  },
  resendOrderConfirmationSuccess: {
    id: 'notifications.resendOrderConfirmationSuccess',
    defaultMessage: 'Order confirmation sent!',
    description: '',
  },
  resendOrderConfirmationError: {
    id: 'notifications.resendOrderConfirmationError',
    defaultMessage: 'Order confirmation could not be sent.',
    description: 'Shown when resend order confirmation fails',
  },
  BasketHasIssues: {
    id: 'notifications.BasketHasIssues',
    defaultMessage: 'Finalizing basket failed.',
    description: 'Shown when finalizing basket fails ',
  },
});
// To page presentation keys
export const orderMessages = defineMessages({
  title: {
    id: 'order.title',
    defaultMessage: 'Your order has been confirmed!',
    description: 'Title on Order Confirmation',
  },
  subtitle: {
    id: 'order.subtitle',
    defaultMessage:
      'You will receive an order confirmation by e-mail shortly etc etc etc',
    description: 'Subtitle on Order Confirmation',
  },
  orderSummary: {
    id: 'order.orderSummary',
    defaultMessage: 'Order Summary',
    description: 'Order Summary on Order Confirmation',
  },
  orderNumber: {
    id: 'order.orderNumber',
    defaultMessage: 'Order no. #',
    description: 'Label for Order number on order pages',
  },
  discounts: {
    id: 'order.orderDiscount',
    defaultMessage: 'Discounts',
    description: 'Label for discounts on order pages',
  },
  fees: {
    id: 'order.orderFees',
    defaultMessage: 'Fees',
    description: 'Label for fees on order pages',
  },
  noFees: {
    id: 'order.noFees',
    defaultMessage: 'No fees',
    description:
      'Label shown on order confirmation if there is no related fee to a product',
  },
  deliveryFees: {
    id: 'order.deliveryFees',
    defaultMessage: 'Delivery fees',
    description: 'Label for delivery fees on order pages',
  },
  discount: {
    id: 'order.discount',
    defaultMessage: 'discount',
    description:
      'Label visible on order details page if a discount was applied',
  },
  orderConfirmed: {
    id: 'order.orderConfirmed',
    defaultMessage: 'Order Confirmed',
    description: 'Order confirmed message on order pages',
  },
  seeYouSoon: {
    id: 'order.seeYouSoon',
    defaultMessage:
      'We hope that you will be completely satisfied with your order and look forward to seeing you again soon',
    description: 'See you soon message on order confirmation page',
  },
  backToHome: {
    id: 'order.backToHome',
    defaultMessage: 'Back to main page',
    description: 'Back to main page link',
  },
  voucherUsed: {
    id: 'order.voucherUsed',
    defaultMessage: 'Vouchers used',
    description: 'Vouchers used on Order Confirmation',
  },
  loadingMessageCheckout: {
    id: 'basket.loadingMessageCheckout',
    defaultMessage: "We're working on your order",
    description: '',
  },
});

// To page presentation keys
export const accountMessages = defineMessages({
  title: {
    id: 'account.title',
    defaultMessage: 'Account Information',
    description: '',
  },
  subtitle: {
    id: 'account.subtitle',
    defaultMessage:
      'You can change your login information at any time, but the master data for your invoice account can only be changed by contacting GoGift on',
    description: '',
  },
  titleCreate: {
    id: 'account.titleCreate',
    defaultMessage: 'Create Account',
    description: '',
  },
  subtitleCreate: {
    id: 'account.subtitleCreate',
    defaultMessage:
      'Once you have created your account it will be manually approved for creditr. You can still pay by credit card while waiting for your account to be approved.',
    description: '',
  },
  or: {
    id: 'account.or',
    defaultMessage: 'or',
    description: '',
  },
  countryNotSupported: {
    id: 'account.countryNotSupported',
    defaultMessage:
      'Currently we only support businesses from a limited selection of countries. Your country might not be supported at this time. Contact customer service if you have any questions.',
    description:
      'Message shown on account pages to let users know that we might not let them enter the site, due to country restrictions',
  },
});

// To page presentation keys
export const loginMessages = defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Hello,',
    description: 'Title on login/welcome page',
  },
  subtitle: {
    id: 'login.subtitle',
    defaultMessage: 'What are you here to do today?',
    description: 'Subtitle on login/welcome page',
  },
  redeemTitle: {
    id: 'login.redeemTitle',
    defaultMessage: 'I received a Global Gift Card!',
    description: 'Title for redeem users',
  },
  redeemSubtitle: {
    id: 'login.redeemSubtitle',
    defaultMessage: 'Go to our redeem site to choose your gift',
    description: 'Subtitle for redeem users',
  },
  redeemButton: {
    id: 'login.redeemButton',
    defaultMessage: 'Go to Redeem',
    description: 'Go to Redeem button label',
  },
  businessTitle: {
    id: 'login.businessTitle',
    defaultMessage: 'I’m here as a business client',
    description: 'Title for business users',
  },
  businessSubtitle: {
    id: 'login.businessSubtitle',
    defaultMessage: 'Create or log in to your account',
    description: 'Subtitle for business users',
  },
  loginButton: {
    id: 'login.loginButton',
    defaultMessage: 'Login to Business',
    description: 'Business login button label',
  },
  createButton: {
    id: 'login.createButton',
    defaultMessage: 'Create account',
    description: 'Create account button label',
  },
  currentCountry: {
    id: 'login.currentCountry',
    defaultMessage: 'We placed you in',
    description: 'Label for "we placed you in [country]"',
  },
  notCorrect: {
    id: 'login.notCorrect',
    defaultMessage: 'Not correct?',
    description: 'Not correct label',
  },
  selectCountry: {
    id: 'login.selectCountry',
    defaultMessage: 'Change here',
    description: 'Select country link label',
  },
});

export const accountFormMessages = defineMessages({
  wizardStepThree: {
    id: 'accountForm.wizardStepThree',
    defaultMessage: 'Step 3: Create a company department',
    description: '',
  },
  contactPerson: {
    id: 'accountForm.contactPerson',
    defaultMessage: 'Contact person Information',
    description: '',
  },
  companyInformation: {
    id: 'accountForm.companyInformation',
    defaultMessage: 'Company Information',
    description: '',
  },
  defaultCurrency: {
    id: 'accountForm.defaultCurrency',
    defaultMessage: 'Preferred currency',
    description: 'Default currency',
  },
  fullName: {
    id: 'accountForm.fullName',
    defaultMessage: 'Full name',
    description: '',
  },
  company: {
    id: 'accountForm.company',
    defaultMessage: 'Company Name',
    description: '',
  },
  companyPlaceholderText: {
    id: 'accountForm.companyPlaceholderText',
    defaultMessage: 'companyPlaceholderText',
    description: '',
  },
  cvr: {
    id: 'accountForm.cvr',
    defaultMessage: 'CVR/ SE-Nr.',
    description: '',
  },
  vatCountryCode: {
    id: 'accountForm.vatCountryCode',
    defaultMessage: 'Vat Country',
    description: '',
  },
  invoiceRecipients: {
    id: 'accountForm.invoiceRecipients',
    defaultMessage: 'Invoice recipient email',
    description: '',
  },
  differentDeliveryAddress: {
    id: 'accountForm.differentDeliveryAddress',
    defaultMessage: 'Use a different delivery address',
    description:
      'Toggle checkbox for displaying delivery address in addition to default address',
  },
  deliveryAddress: {
    id: 'accountForm.deliveryAddress',
    defaultMessage: 'Company delivery address',
    description: 'Header for delivery address when requested',
  },
  email: {
    id: 'accountForm.billingEmail',
    defaultMessage: 'Invoice E-mail',
    description: '',
  },
  emailPlaceholderText: {
    id: 'accountForm.billingEmailPlaceholderText',
    defaultMessage: 'Insert e-mail here…',
    description: '',
  },
  phoneNumber: {
    id: 'accountForm.phoneNumber',
    defaultMessage: 'Phone number',
    description: '',
  },
  phoneNumberPlaceholderText: {
    id: 'accountForm.phoneNumberPlaceholderText',
    defaultMessage: 'Insert mobile no here…',
    description: '',
  },
  country: {
    id: 'accountForm.country',
    defaultMessage: 'Country',
    description: '',
  },
  city: {
    id: 'accountForm.city',
    defaultMessage: 'City',
    description: '',
  },
  postCode: {
    id: 'accountForm.postCode',
    defaultMessage: 'Postal Code',
    description: '',
  },
  address: {
    id: 'accountForm.address',
    defaultMessage: 'Address',
    description: '',
  },
  attention: {
    id: 'accountForm.attention',
    defaultMessage: 'Attention',
    description: '',
  },
  line1: {
    id: 'accountForm.line1',
    defaultMessage: 'Line 1',
    description: '',
  },
  line2: {
    id: 'accountForm.line2',
    defaultMessage: 'Optional line 2',
    description: '',
  },
  eanNumber: {
    id: 'accountForm.eanNumber',
    defaultMessage: 'EAN number',
    description: '',
  },
  required: {
    id: 'accountForm.required',
    defaultMessage: 'required',
    description: '',
  },
  update: {
    id: 'accountForm.update',
    defaultMessage: 'Update Account',
    description: '',
  },
  create: {
    id: 'accountForm.create',
    defaultMessage: 'Create Account',
    description: '',
  },
});

export const giftcardMessages = defineMessages({
  header: {
    id: 'giftcard.Header',
    defaultMessage: 'Header',
    description: '',
  },
  subtitle: {
    id: 'giftcard.Subtitle',
    defaultMessage: 'Subtitle',
    description: '',
  },
  byemail: {
    id: 'giftcard.byemail',
    defaultMessage: 'DescriptionEmail',
    description: '',
  },
  bysms: {
    id: 'giftcard.bysms',
    defaultMessage: 'DescriptionPhone',
    description: '',
  },
  bypin: {
    id: 'giftcard.bypin',
    defaultMessage: 'DescriptionPin',
    description: '',
  },
  downloadHeader: {
    id: 'giftcard.downloadHeader',
    defaultMessage: 'Thank you for using GoGift!',
    description: '',
  },
  downloadSubtitle: {
    id: 'giftcard.downloadSubtitle',
    defaultMessage: 'Your download should start shortly.',
    description: '',
  },
  downloadInfo: {
    id: 'giftcard.downloadInfo',
    defaultMessage: 'If your download is not starting,',
    description: '',
  },
  downloadBundleInfo: {
    id: 'giftcard.downloadBundleInfo',
    defaultMessage:
      "Wow you ordered a lot of gift cards. That's cool :). Since you ordered so many you can choose to download your gift cards individiually or by product.",
    description: '',
  },
  downloadButton: {
    id: 'giftcard.downloadButton',
    defaultMessage: 'click here',
    description: '',
  },
  download: {
    id: 'giftcard.download',
    defaultMessage: 'Download',
    description: '',
  },
  downloadAll: {
    id: 'giftcard.downloadAll',
    defaultMessage: 'Download all',
    description: '',
  },
  downloadError: {
    id: 'giftcard.downloadError',
    defaultMessage:
      'Error downloading voucher. Please try again or contact support.',
    description: '',
  },
});

export const giftcardFormMessages = defineMessages({
  ownerEmail: {
    id: 'giftcardForm.ownerEmail',
    defaultMessage: 'Owner email',
    description: '',
  },
  ownerPhone: {
    id: 'giftcardForm.ownerPhone',
    defaultMessage: 'Owner phone',
    description: '',
  },
  ownerPin: {
    id: 'giftcardForm.ownerPin',
    defaultMessage: 'Owner pin',
    description: '',
  },
  ownerEmailPlaceholder: {
    id: 'giftcardForm.ownerEmailPlaceholder',
    defaultMessage: 'xxx@example.com',
    description: 'Placeholder for Email input on Verify Owner',
  },
  ownerPhonePlaceholder: {
    id: 'giftcardForm.ownerPhonePlaceholder',
    defaultMessage: '+4670000000',
    description: 'Placeholder for Phone input on Verify Owner',
  },
  ownerPinPlaceholder: {
    id: 'giftcardForm.ownerPinPlaceholder',
    defaultMessage: 'pin',
    description: 'Placeholder for Pin input on Verify Owner',
  },
  verifyOwner: {
    id: 'giftcardForm.verifyOwner',
    defaultMessage: 'Verify',
    description: '',
  },
  inputError: {
    id: 'giftcardForm.inputError',
    defaultMessage: 'InputError',
    description: '',
  },
});

export const giftcardRedeemMessages = defineMessages({
  congratulations: {
    id: 'giftcardRedeem.congratulations',
    defaultMessage: 'CONGRATULATIONS ON YOUR',
    description: '',
  },
  productTitle: {
    id: 'giftcardRedeem.productTitle',
    defaultMessage: 'Gift Card',
    description: '',
  },
  description: {
    id: 'giftcardRedeem.description',
    defaultMessage:
      'You are now ready to choose between gift cards from well-known brands.',
    description: '',
  },
  redeemButton: {
    id: 'giftcardRedeem.redeemButton',
    defaultMessage: 'CLICK HERE TO REDEEM NOW',
    description: '',
  },
  instructionsHeader: {
    id: 'giftcardRedeem.instructionsHeader',
    defaultMessage: 'HOW TO REDEEM YOUR',
    description: '',
  },
  instructions: {
    id: 'giftcardRedeem.instructions',
    defaultMessage:
      '<li>Start redemption above</li><li>Add gift card(s) to your basket</li><li>Checkout and finalize your order</li><li>Use your gift card(s) at retailer(s)</li>',
    description: '',
  },
  downloadButton: {
    id: 'giftcardRedeem.downloadButton',
    defaultMessage: 'DOWNLOAD AS PDF',
    description: '',
  },
  csButton: {
    id: 'giftcardRedeem.csButton',
    defaultMessage: 'CUSTOMER SERVICES',
    description: '',
  },
  disclaimer: {
    id: 'giftcardRedeem.disclaimer',
    defaultMessage:
      'It is not possible to use The Global Gift Card <br /> directly in shops or at attractions.',
    description: '',
  },
});

export const greetingCategoryMessages = defineMessages({
  birthday: {
    id: 'greetingCategory.birthday',
    defaultMessage: 'Birthday',
    description: 'Birthday category',
  },
  christmas: {
    id: 'greetingCategory.christmas',
    defaultMessage: 'Christmas',
    description: 'Christmas category',
  },
  easter: {
    id: 'greetingCategory.easter',
    defaultMessage: 'Easter',
    description: 'Easter category',
  },
  corporate: {
    id: 'greetingCategory.corporate',
    defaultMessage: 'Corporate',
    description: 'Corporate category',
  },
  love: {
    id: 'greetingCategory.love',
    defaultMessage: 'Love',
    description: 'Love category',
  },
  misc: {
    id: 'greetingCategory.misc',
    defaultMessage: 'Miscellaneous',
    description: 'Miscellaneous category',
  },
  occasions: {
    id: 'greetingCategory.occasions',
    defaultMessage: 'Occasions',
    description: 'Occasions category',
  },
  'thank-you': {
    id: 'greetingCategory.thank-you',
    defaultMessage: 'Thank you',
    description: 'Thank you category',
  },
});

export const userMessages = defineMessages({
  myBack: {
    id: 'user.myBack',
    defaultMessage: 'Back to my pages',
    description: 'Back link label on my sub pages',
  },
  myHeader: {
    id: 'user.myHeader',
    defaultMessage: 'GoGift Business',
    description: 'My page header',
  },
  myHello: {
    id: 'user.myHello',
    defaultMessage: 'Hello, {name} from {company}',
    description: 'Hello, name from company',
  },
  myIntroduction: {
    id: 'user.myIntroduction',
    defaultMessage: 'Welcome to your account page. Click on, have fun!',
    description: 'Introduction text below Hello user on my page',
  },
  myHelloB2C: {
    id: 'user.myHelloB2C',
    defaultMessage: 'Hello, {name}',
    description: 'Hello, name',
  },
  myIntroductionB2C: {
    id: 'user.myIntroductionB2C',
    defaultMessage: 'Create a department!',
    description:
      'Introduction text below Hello user on my page for authenticated users without a department',
  },
  myDepartmentStatusActivePendingReview: {
    id: 'user.myDepartmentStatusActivePendingReview',
    defaultMessage: 'Department is pending review.',
    description:
      'Department is pending review - and most likely is not approved for invoice',
  },
  myDepartmentStatusBlocked: {
    id: 'user.myDepartmentStatusBlocked',
    defaultMessage: 'Department is blocked.',
    description: 'Department is blocked',
  },
  myDepartmentIntroduction: {
    id: 'user.myDepartmentIntroduction',
    defaultMessage: 'Select department to work on behalf',
    description:
      'Introduction text below Hello user on select department page for authenticated users with several departments',
  },
  myDepartmentIntroductionSelect: {
    id: 'user.myDepartmentIntroductionSelect',
    defaultMessage: 'Change department to work on behalf',
    description:
      'Introduction text below Hello user on select department page when changing department',
  },
  mySelectDepartment: {
    id: 'user.mySelectDepartment',
    defaultMessage: 'Select another department',
    description: 'Select department link label',
  },
  myAccount: {
    id: 'user.myAccount',
    defaultMessage: 'My Account',
    description: 'My account link label',
  },
  myAccountCreate: {
    id: 'user.myAccountCreate',
    defaultMessage: 'Create Department',
    description: 'Create account link label',
  },
  myPage: {
    id: 'user.myPage',
    defaultMessage: 'My Page',
    description: 'My page link label',
  },
  myOrders: {
    id: 'user.myOrders',
    defaultMessage: 'My Orders',
    description: 'My orders link label',
  },
  myLogout: {
    id: 'user.myLogout',
    defaultMessage: 'Logout of business',
    description: 'Logout button label',
  },
  mobileUser: {
    id: 'user.mobileUser',
    defaultMessage: 'Signed in as',
    description: 'Signed in user message in mobile menu',
  },
  mobileLogout: {
    id: 'user.mobileLogout',
    defaultMessage: 'Logout',
    description: 'Logout link in mobile menu',
  },
});

// To page presentation keys
export const basketIssues = defineMessages({
  header: {
    id: 'basketIssues.header',
    defaultMessage: 'Please check the following.',
    description: '',
  },
  MultipleRecipientCountries: {
    id: 'basketIssues.MultipleRecipientCountries',
    defaultMessage: 'Basket must have only one recipient country.',
    description: '',
  },
  ProductNotAllowedWhileRedeeming: {
    id: 'basketIssues.ProductNotAllowedWhileRedeeming',
    defaultMessage: 'Marked products are not allowed while redeeming.',
    description: '',
  },
});

export const ieWarningMessages = defineMessages({
  title: {
    id: 'ieWarning.title',
    defaultMessage: 'ieWarning.title',
    description: 'IE warning modal title',
  },
  body: {
    id: 'ieWarning.body',
    defaultMessage: 'ieWarning.body',
    description: 'IE warning modal content (HTML compatible)',
  },
  confirm: {
    id: 'ieWarning.confirm',
    defaultMessage: 'ieWarning.confirm',
    description: 'IE warning modal confirm button text',
  },
});

// To page presentation keys
export const brandMessages = defineMessages({
  all: {
    id: 'brand.all',
    defaultMessage: 'All',
    description: 'First letter in the index filter',
  },
  alphabet: {
    id: 'brand.alphabet',
    defaultMessage: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    description:
      'Alphabet letters, they are used as a links and filters, all capital with no space in between',
  },
  brandTitle: {
    id: 'brand.title',
    defaultMessage: 'Brands',
    description: 'First title in the page',
  },
});

// @flow

import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';

const defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const createLink = ({ graphqlHost, cmsHost }) =>
  from([
    onError(({ graphQLErrors, networkError, operation }) => {
      const { operationName = '', variables = {} } = operation;

      if (graphQLErrors)
        graphQLErrors.map(({ message }) =>
          console.warn(
            `[GraphQL error client]: Message: ${message}, operationName: ${operationName}, variables: ${JSON.stringify(
              variables,
            )}`,
          ),
        );
      if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    new HttpLink({
      uri: operation => {
        const { operationName = '', variables = {} } = operation;
        const { service } = operation.getContext();
        console.warn(
          `[GraphQL Operation client]: operationName: ${operationName}, variables: ${JSON.stringify(
            variables,
          )}`,
        );
        if (service === 'cms') {
          return `${cmsHost}/graphql`;
        }

        // Defaults to api service
        return `${graphqlHost}/graphql`;
      },
      credentials: 'same-origin',
    }),
  ]);

const cache = createCache();

export default function createApolloClient({ graphqlHost, cmsHost }) {
  return new ApolloClient({
    link: createLink({ graphqlHost, cmsHost }),
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
    defaultOptions,
  });
}

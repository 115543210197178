/**
 * Inspired from Material UI  component (https://material-ui.com/layout/grid/)
 */

/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './List.scss';

const List = ({
  alignContent,
  alignItems,
  className: classNameProp,
  component: Component,
  container,
  direction,
  item,
  grow,
  justify,
  spacing,
  wrap,
  zeroMinWidth,
  size,
  ...other
}) => {
  const className = cx(
    container && s.container,
    item && s.item,
    item && grow && s['item-grow'],
    zeroMinWidth && s['zero-min-width'],
    spacing !== 0 && container && s[`spacing-${spacing}`],
    spacing !== 0 && item && s[`spacing-item-${spacing}`],
    direction !== List.defaultProps.direction && s[`direction-${direction}`],
    wrap !== List.defaultProps.wrap && s[`wrap-${wrap}`],
    alignItems !== List.defaultProps.alignItems &&
      s[`align-items-${alignItems}`],
    alignContent !== List.defaultProps.alignContent &&
      s[`align-content-${alignContent}`],
    justify !== List.defaultProps.justify && s[`justify-${justify}`],
    size && s[`${size}`],
    classNameProp,
  );

  return <Component className={className} {...other} />;
};

List.propTypes = {
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   */
  alignContent: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   */
  alignItems: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.node,
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  container: PropTypes.bool,
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   */
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  item: PropTypes.bool,
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   */
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   */
  spacing: PropTypes.oneOf([0, 4, 8, 12, 16, 24, 32, 40]),
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   */
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   */
  zeroMinWidth: PropTypes.bool,
  grow: PropTypes.bool,
  size: PropTypes.string,
};

List.defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  component: 'ul',
  container: false,
  direction: 'row',
  item: false,
  justify: 'flex-start',
  spacing: 0,
  wrap: 'wrap',
  zeroMinWidth: false,
  grow: false,
  size: undefined,
  children: undefined,
  className: undefined,
};

export default withStyles(s)(List);

import React from 'react';
import PropTypes from 'prop-types';
import { RawIntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import ApplicationContext from './ApplicationContext';
import {
  getBasket,
  createBasket,
  updateBasketItem as updateBasketCurrency,
} from '../actions/basket';

import { setSalesChannel } from '../actions/config';
import {
  getSalesChannelBasketId,
  setSalesChannelBasketId,
  getSalesChannelId,
  clearUserStorage,
} from '../helpers/userStorage';
import { setPath } from '../actions/intl';
import {
  DISPLAY_CATALOG,
  REDEEM_PATH,
  SALES_CHANNELS,
} from '../constants/config';
import IEWarning from './IEWarning/IEWarning';
import { triggerUserLoginStatusEvent } from '../helpers/googleTagManager';

class App extends React.PureComponent {
  static propTypes = {
    context: PropTypes.shape().isRequired,
    children: PropTypes.element.isRequired,
    insertCss: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    import('webfontloader').then(WebFontLoader => {
      WebFontLoader.load({
        timeout: 3000,
        google: {
          families: ['Poppins:300,400,600:latin'],
        },
      });
    });

    const { store, pathname } = this.props.context;

    const {
      config: { salesChannelId: currentSalesChannelId },
      intl: { currency, countryCode },
    } = store.getState();

    const isDisplayCatalog = pathname.indexOf(DISPLAY_CATALOG) !== -1;
    // Don't authenticate and create basket for Display Catalog
    if (isDisplayCatalog) return;
    triggerUserLoginStatusEvent({ isB2bUser: false });

    // Verify sales channel. User may be in the middle of a redeem session
    let salesChannelId = currentSalesChannelId;
    const lastSalesChannelId = getSalesChannelId();
    const { basketId: lastSalesChannelBasketId } =
      getSalesChannelBasketId({
        salesChannelId: lastSalesChannelId,
        countryCode,
      }) || {};
    const { vouchers } = await store.dispatch(
      getBasket(lastSalesChannelBasketId),
    );
    if (
      lastSalesChannelId &&
      lastSalesChannelId !== salesChannelId &&
      vouchers?.length
    ) {
      const { id } = await store.dispatch(setSalesChannel(lastSalesChannelId));
      if (id) {
        salesChannelId = id;
      }
    }

    let basketId;
    let { basket } = store.getState();
    /**
     * Remove redeem basket from local storage when there are no vouchers associated with it
     * Should remove previous data when it is in redeem or portal flow and associated vouchers are not present
     */
    if (
      lastSalesChannelId &&
      lastSalesChannelId !== SALES_CHANNELS.B2B &&
      lastSalesChannelId !== SALES_CHANNELS.B2C &&
      !vouchers?.length
    ) {
      clearUserStorage();
      // when on redeem page set REDEEM sales channel to see correct countries
      if (pathname.indexOf(REDEEM_PATH) !== -1) {
        await store.dispatch(setSalesChannel(SALES_CHANNELS.REDEEM));
      } else {
        await store.dispatch(setSalesChannel(SALES_CHANNELS.B2C));
      }
      basket = {};
    }

    if (basket.id) {
      store.dispatch({
        type: 'INITIAL_BASKET',
        payload: {
          initialBasket: true,
        },
      });
      if (
        basket.paymentCurrency &&
        basket.paymentCurrency.toUpperCase() !== currency.toUpperCase()
      ) {
        await store.dispatch(
          updateBasketCurrency({
            paymentCurrency: currency.toUpperCase(),
            doClearProducts: false,
          }),
        );
      }
    } else {
      const { basketId: receivedBasketId } =
        getSalesChannelBasketId({
          salesChannelId,
          countryCode,
        }) || {};

      basketId = receivedBasketId;

      if (basketId) {
        const {
          id,
          salesChannelId: basketSalesChannelId,
          paymentCurrency,
        } = await store.dispatch(getBasket(basketId));
        if (basketSalesChannelId !== salesChannelId) {
          await store.dispatch(createBasket(salesChannelId));
          return;
        }
        if (id) {
          setSalesChannelBasketId({ salesChannelId, basketId, countryCode });

          if (paymentCurrency.toUpperCase() !== currency.toUpperCase()) {
            await store.dispatch(
              updateBasketCurrency({
                paymentCurrency: currency.toUpperCase(),
                doClearProducts: false,
              }),
            );
          }
        }
        basketId = id;
      }
      if (!basketId) {
        await store.dispatch(createBasket(salesChannelId));
      }
    }

    store.dispatch(setPath({}));
  }

  async componentDidUpdate() {
    const { store, pathname } = this.props.context;
    // Don't update basket for Display Catalog
    if (pathname.indexOf(DISPLAY_CATALOG) !== -1) return;

    const {
      config: { salesChannelId },
      intl: { countryCode },
    } = store.getState();

    if (
      salesChannelId === SALES_CHANNELS.REDEEM ||
      salesChannelId === SALES_CHANNELS.B2C
    ) {
      const { basketId: receivedBasketId } =
        getSalesChannelBasketId({
          salesChannelId,
          countryCode,
        }) || {};
      if (receivedBasketId) {
        await store.dispatch(getBasket(receivedBasketId));
      } else {
        await store.dispatch(createBasket(salesChannelId));
      }
    }
  }

  render() {
    const { insertCss, context, children } = this.props;
    const { client, locale, intl, store } = context;
    return (
      <ApplicationContext.Provider value={context}>
        <ReduxProvider store={store}>
          <RawIntlProvider value={intl} locale={locale}>
            <StyleContext.Provider value={{ insertCss }}>
              <ApolloProvider client={client}>
                <IEWarning />
                {React.Children.only(children)}
              </ApolloProvider>
            </StyleContext.Provider>
          </RawIntlProvider>
        </ReduxProvider>
      </ApplicationContext.Provider>
    );
  }
}

export default App;
